/** @format */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'common/Button';
import FormTemplate from 'common/FormTemplate';
import { Input } from 'common/Input';
import {
  Label,
  Form,
  SectionTitle,
  ButtonContainer,
  OptionValue,
  SupContainer,
  QuestionsHeadContainer,
  QuestionBodyContainer,
  SwitchLabel,
  OptionWrapper,
  QuestionTypeScale,
  MultiChoiceContainer,
  SelectLabel,
  QuestionDropdownContainer,
  SelectWrapper,
  CheckboxWrapper,
  SliderWrapper,
  WeightWrapper,
  WeightAndPointsText,
  ChoiceOptionsWithPoints,
  DrawerBodyTitle,
  ExampleSpan,
  InfoLabel,
  OptionsAndPointsLabels,
  ScaleDetailsBox,
  ImgLoadingContainer,
  LabelImag,
  ImageContainer,
  FileName,
  QuestionNumber,
} from '../style';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { SwitchBtn } from 'common/Switch';
import { Checkbox } from 'common/Checkbox';
import { Select } from 'common/Select';
import DeleteIcon from 'assets/svg/icon-delete';
import {
  EvaluationFormProps,
  questionTypesProps,
  QuestionsProps,
} from 'infrastructure/interfaces/evaluationForm.interface';
import { Select as AntDSelect } from 'antd';
import i18n from 'i18n';
import PlusBtnIcon from 'assets/svg/icon-add-question';
import { useStore } from 'infrastructure/zustandStore/evaluation-details-store';
import { Error } from '../../style';
import { WrappedSelect } from '../wrapperSelect';
import { Slider } from 'common/Slider';
import InfoIcon from 'assets/svg/info-icon';
import Drawer from 'common/Drawer';
import FileUploadIcon from 'assets/svg/fileUploadIcon';
import { axiosApiInstance } from 'infrastructure/utils/api';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import { Progress } from 'common/Progress';
import RedIconDelete from 'assets/svg/red-icon-delete';
import Colors from 'infrastructure/utils/Colors';

const { Option } = AntDSelect;
const { v4: uuidv4 } = require('uuid'); //using for the draggable component and the delete functionality for multi and single choices

const reorder = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const EditEvaluationTemplateForm: React.FC<EvaluationFormProps> = ({
  questionTypes,
  mockedArr,
  handleAddQuestionBlockOrMultiChoiceQuestion,
  handleOnChangeSwitchButton,
  handleInputChange,
  handleSelectOnChange,
  handleRemoveQuestionBlockOrMultiChoiceQuestionsItem,
  handleSubmit,
  errors,
  register,
  editableData,
  loading,
  onExtraImagesChange,
  onExtraVideosChange,
  handleQuestionCategoryChange,
  question_categories,
}): JSX.Element => {
  const { t } = useTranslation();
  const [state, setState] = useState<any>(editableData?.questions);
  // @ts-nocheck
  const [, setMax] = useState(0);
  const [, setMin] = useState(0);
  const [evaluationDetailsData] = useStore((state: any) => [
    state.evaluationDetailsData,
  ]);

  const [visible, setVisible] = useState(false);
  const [multiChoiceDrawerVisibilty, setMultiChoiceDrawerVisibilty] =
    useState(false);

  const [singleChoiceDrawerVisibilty, setSingleChoiceDrawerVisibilty] =
    useState(false);

  const [fileLoading, setFileLoading] = useState(false);

  const [fileName, setFileName] = useState('');

  const [showImage, setShowImage] = useState(null);

  const [menuIsOpen, setMenuIsOpen] = useState<any>({
    key: false,
  });

  const showDrawer = () => {
    setVisible(true);
  };

  const showMultiChoiceDrawer = () => {
    setMultiChoiceDrawerVisibilty(true);
  };

  const showSingleChoiceDrawer = () => {
    setSingleChoiceDrawerVisibilty(true);
  };

  useEffect(() => {
    setState(editableData?.questions);

    editableData?.questions?.map((question: any) => {
      if (question.options) {
        question?.options?.forEach((object: { id: string | number }) => {
          object.id = uuidv4();
          let id = object.id;
          return id;
        });
      }
    });
  }, [editableData.questions]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(state, result.source.index, result.destination.index);

    useStore.setState({
      evaluationDetailsData: { ...evaluationDetailsData, questions: items },
    });
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'rgba(48,48,48,0.04)',
      borderRadius: '10px',
      borderColor: 'rgba(48,48,48,0.04)',
      minHeight: '51px',
      fontFamily: 'Famtree',
    }),
  };

  const renderTotalPointsAndPercentageForWeightTemplate = (
    item: QuestionsProps
  ) => {
    if (editableData.type === 'points') {
      if (
        item.questionType?.id === 8 ||
        item.questionType?.id === 2 ||
        item.questionType?.id === 9
      )
        return (
          <WeightWrapper>
            <WeightAndPointsText>
              <Trans
                values={
                  item.questionType?.id === 2
                    ? {
                        count: item.total_points || 0,
                      }
                    : item.questionType?.id === 9
                    ? {
                        count: item.total_points > 0 ? item.total_points : 0,
                      }
                    : item.questionType?.id === 8
                    ? {
                        count: item.total_points || 0,
                      }
                    : 0
                }
              >
                total_points
              </Trans>
            </WeightAndPointsText>
            <WeightAndPointsText> {' - '}</WeightAndPointsText>
            <WeightAndPointsText>
              <Trans
                values={
                  item.questionType.id === 2
                    ? {
                        count: item.weight_percentage || 0,
                      }
                    : item.questionType.id === 9
                    ? {
                        count: item.weight_percentage || 0,
                      }
                    : item.questionType.id === 8
                    ? {
                        count: item.weight_percentage || 0,
                      }
                    : 0
                }
              >
                weight
              </Trans>
            </WeightAndPointsText>
          </WeightWrapper>
        );
    }
  };

  const renderDefaultTemplate = (value: QuestionsProps, index: number) => {
    if (value.questionType?.id === 8 || value.questionType?.id === 9) {
      return (
        <>
          {value.options?.length &&
            value.options?.map(
              (item: { option: string; id: string }, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <MultiChoiceContainer>
                      {item.option !== 'جميع الاجابات لا تنطبق' &&
                        (i === 4 ? (
                          <DeleteIcon
                            onClick={() =>
                              handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                index,
                                item.id,
                                'multiChoice'
                              )
                            }
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '23px',
                              transform: 'translateY(-50%)',
                            }}
                          />
                        ) : i === 0 ? (
                          <PlusBtnIcon
                            id='plus_button'
                            onClick={() =>
                              handleAddQuestionBlockOrMultiChoiceQuestion(
                                index,
                                i,
                                'multiChoice'
                              )
                            }
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '23px',
                              transform: 'translateY(-50%)',
                            }}
                          />
                        ) : (
                          <>
                            <PlusBtnIcon
                              id='plus_button'
                              onClick={() =>
                                handleAddQuestionBlockOrMultiChoiceQuestion(
                                  index,
                                  i,
                                  'multiChoice'
                                )
                              }
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '56px',
                                transform: 'translateY(-50%)',
                              }}
                            />
                            <DeleteIcon
                              onClick={() =>
                                handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                  index,
                                  item.id,
                                  'multiChoice'
                                )
                              }
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '23px',
                                transform: 'translateY(-50%)',
                              }}
                            />
                          </>
                        ))}
                      {item.option !== 'جميع الاجابات لا تنطبق' && (
                        <Input
                          id='options'
                          placeholder={t('option_head')}
                          name={`options[${index}]`}
                          type='text'
                          dir='true'
                          errorName={errors?.options && errors.options[index]}
                          onChange={(e: any) =>
                            handleInputChange(e, index, i, 'form')
                          }
                          ref={register({ required: true })}
                          defaultValue={item.option}
                          key={i}
                        />
                      )}
                    </MultiChoiceContainer>
                    {errors.options &&
                      errors?.options[index]?.types?.required && (
                        <Error>
                          <Trans>evaluation_template_options_required</Trans>
                        </Error>
                      )}
                  </React.Fragment>
                );
              }
            )}

          {value.questionType.id === 8 && (
            <ChoiceOptionsWithPoints>
              <Input
                id='questions-tip'
                name='questions-tip'
                type='text'
                dir='true'
                placeholder={t('all_answers_not_applicable')}
                disabled
                style={{
                  cursor: 'not-allowed',
                }}
              />
            </ChoiceOptionsWithPoints>
          )}
        </>
      );
    }
    if (value?.questionType?.id === 2)
      return (
        <>
          <SliderWrapper>
            <QuestionTypeScale>
              <Label>
                <Trans>slider_range</Trans>
              </Label>
              <Input
                id='min'
                name={`min[${index}]`}
                type='number'
                onWheel={(e: any) => e.target.blur()}
                dir='true'
                maxLength='3'
                min='1'
                errorName={errors?.min && errors.min[index]}
                width={'optionsWidth'}
                onChange={(e2: any) => {
                  setMin(parseInt(e2?.target.value));
                  handleInputChange(e2, index, undefined, 'form');
                }}
                ref={register({ required: true, min: 1 })}
                placeholder={t('min')}
                defaultValue={value?.scale?.min}
              />

              <Input
                id='max'
                name={`max[${index}]`}
                type='number'
                onWheel={(e: any) => e.target.blur()}
                maxLength='3'
                dir='true'
                errorName={errors?.max && errors.max[index]}
                width={'optionsWidth'}
                onChange={(e3: any) => {
                  setMax(parseInt(e3?.target.value));
                  handleInputChange(e3, index, undefined, 'form');
                }}
                placeholder={t('max')}
                ref={register({ required: true, min: 1 })}
                defaultValue={value?.scale?.max}
              />

              <Input
                id='step'
                name={`step[${index}]`}
                type='number'
                onWheel={(e: any) => e.target.blur()}
                dir='true'
                maxLength='3'
                errorName={errors?.step && errors.step[index]}
                width={'optionsWidth'}
                onChange={(e4: any) => {
                  handleInputChange(e4, index, undefined, 'form');
                }}
                ref={register({
                  required: true,
                  min: 1,
                  validate: (el: string) => {
                    return parseInt(el) < value.scale?.max;
                  },
                })}
                placeholder={t('step')}
                defaultValue={value?.scale?.step}
              />
            </QuestionTypeScale>
            {errors.min && errors?.min[index]?.types?.min && (
              <Error>
                <Trans>zero_error_message</Trans>
              </Error>
            )}
            {errors.min && errors?.min[index]?.types?.required && (
              <Error>
                <Trans>required_error_message</Trans>
              </Error>
            )}
            {errors.step && errors?.step[index]?.types?.validate && (
              <Error>
                <Trans>min_error_message</Trans>
              </Error>
            )}
          </SliderWrapper>
        </>
      );
  };

  const renderPointsTemplate = (value: QuestionsProps, index: number) => {
    if (value.questionType?.id === 8 || value.questionType?.id === 9) {
      return (
        <>
          <OptionsAndPointsLabels>
            <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Trans>options</Trans>
            </Label>
            <InfoLabel>
              <InfoIcon
                onClick={
                  value.questionType?.id === 8
                    ? showMultiChoiceDrawer
                    : showSingleChoiceDrawer
                }
              />
              <Trans>points</Trans>
            </InfoLabel>
            {value.questionType?.id === 8 ? (
              <Drawer
                title='نظام النقاط'
                setVisible={setMultiChoiceDrawerVisibilty}
                open={multiChoiceDrawerVisibilty}
              >
                <DrawerBodyTitle>
                  <Trans>drawer_multi_choice_content</Trans>
                </DrawerBodyTitle>
                <ExampleSpan>
                  <Trans>example</Trans>
                </ExampleSpan>
                <ScaleDetailsBox>
                  <ExampleSpan>حدد مايدل على نظافة الفرع </ExampleSpan>
                  <Label
                    style={{ fontSize: '12px' }}
                    language={i18n.language === 'ar' ? 'ar' : 'en'}
                  >
                    تم توفير المناديل – نقاط الإجابة = 20
                  </Label>
                  <Label
                    style={{ fontSize: '12px' }}
                    language={i18n.language === 'ar' ? 'ar' : 'en'}
                  >
                    تم توفير المعقمات – نقاط الإجابة = 20
                  </Label>
                  <Label
                    style={{ fontSize: '12px' }}
                    language={i18n.language === 'ar' ? 'ar' : 'en'}
                  >
                    الطاولات نظيفة – نقاط الإجابة = 20
                  </Label>
                  <Label
                    style={{ fontSize: '12px' }}
                    language={i18n.language === 'ar' ? 'ar' : 'en'}
                  >
                    جميع الإجابات لا تنطبق – نقاط الإجابة = 0
                  </Label>
                </ScaleDetailsBox>
              </Drawer>
            ) : (
              <Drawer
                title='نظام النقاط'
                setVisible={setSingleChoiceDrawerVisibilty}
                open={singleChoiceDrawerVisibilty}
              >
                <DrawerBodyTitle>
                  <Trans>drawer_single_choice_content</Trans>
                </DrawerBodyTitle>
                <ExampleSpan>
                  <Trans>example</Trans>
                </ExampleSpan>
                <ScaleDetailsBox>
                  <ExampleSpan>هل أبواب المدخل نظيفة؟ </ExampleSpan>
                  <Label
                    style={{ fontSize: '12px' }}
                    language={i18n.language === 'ar' ? 'ar' : 'en'}
                  >
                    نعم – نقاط الإجابة = 50
                  </Label>
                  <Label
                    style={{ fontSize: '12px' }}
                    language={i18n.language === 'ar' ? 'ar' : 'en'}
                  >
                    لا – نقاط الإجابة = 0
                  </Label>
                </ScaleDetailsBox>
              </Drawer>
            )}
          </OptionsAndPointsLabels>
          <div style={{ width: '100%' }}>
            {value.options?.map((item: any, i: number) => {
              return (
                <div key={item.id}>
                  <MultiChoiceContainer>
                    {item.option !== 'جميع الاجابات لا تنطبق' &&
                      (i === 4 ? (
                        <DeleteIcon
                          onClick={() => {
                            handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                              index,
                              item.id,
                              'multiChoice'
                            );
                          }}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '30%',
                            transform: 'translateY(-50%)',
                          }}
                        />
                      ) : // my change here
                      i === 0 ? (
                        <PlusBtnIcon
                          id='plus_button'
                          onClick={() => {
                            handleAddQuestionBlockOrMultiChoiceQuestion(
                              index,
                              i,
                              'multiChoice'
                            );
                          }}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '25%',
                            transform: 'translateY(-50%)',
                          }}
                        />
                      ) : (
                        <>
                          <PlusBtnIcon
                            id='plus_button'
                            onClick={() => {
                              handleAddQuestionBlockOrMultiChoiceQuestion(
                                index,
                                i,
                                'multiChoice'
                              );
                            }}
                          />
                          <DeleteIcon
                            onClick={() => {
                              handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                index,
                                item.id,
                                'multiChoice'
                              );
                            }}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '25%',
                              transform: 'translateY(-50%)',
                            }}
                          />
                        </>
                      ))}

                    {item.option !== 'جميع الاجابات لا تنطبق' && (
                      <ChoiceOptionsWithPoints>
                        <Input
                          id='options'
                          placeholder={t('option_head')}
                          name={`options[${item.id}]`}
                          type='text'
                          dir='true'
                          errorName={errors?.options && errors.options[item.id]}
                          onChange={(e: any) =>
                            handleInputChange(e, index, i, 'form')
                          }
                          ref={register({
                            required: true,
                            maxLength: 200,
                          })}
                          defaultValue={item.option}
                          width='width_80'
                        />
                        <Input
                          id='points'
                          placeholder={t('points')}
                          width='points_w'
                          name={`points[${item.id}]`}
                          type='number'
                          onWheel={(e: any) => e.target.blur()}
                          maxLength='3'
                          dir='true'
                          min='0'
                          errorName={errors?.points && errors.points[item.id]}
                          onChange={(e: any) => {
                            handleInputChange(e, index, i, 'form');
                          }}
                          ref={register({
                            required: true,
                            message: 'error message',
                            min: 0,
                          })}
                          defaultValue={item.points}
                        />
                      </ChoiceOptionsWithPoints>
                    )}
                  </MultiChoiceContainer>
                  {errors.options &&
                    errors?.options[item.id]?.types?.required && (
                      <Error>
                        <Trans>evaluation_template_options_required</Trans>
                      </Error>
                    )}
                  {errors.options &&
                    errors.options[item.id]?.types?.maxLength && (
                      <Error>
                        <Trans values={{ count: 200 }}>
                          maxlenght_error_message
                        </Trans>
                      </Error>
                    )}
                  {errors.points && errors.points[item.id]?.types?.required && (
                    <Error
                      style={{
                        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                      }}
                    >
                      <Trans>points_required</Trans>
                    </Error>
                  )}
                </div>
              );
            })}
            {value.questionType.id === 8 && (
              <ChoiceOptionsWithPoints>
                <Input
                  id='questions-tip'
                  name='questions-tip'
                  type='text'
                  dir='true'
                  placeholder={t('all_answers_not_applicable')}
                  disabled
                  width='width_80'
                  style={{
                    cursor: 'not-allowed',
                  }}
                />
                <Input
                  id='static_choice_points'
                  placeholder={0}
                  type='number'
                  onWheel={(e: any) => e.target.blur()}
                  name={`static_choice_points[${value.id}]`}
                  dir='true'
                  width='points_w'
                  disabled
                />
              </ChoiceOptionsWithPoints>
            )}
            {errors.static_choice_points &&
              errors.static_choice_points[value.id]?.types?.required && (
                <Error>
                  <Trans>points_required</Trans>
                </Error>
              )}
          </div>
        </>
      );
    }

    if (value.questionType?.id === 2) {
      return (
        <SliderWrapper>
          <QuestionTypeScale>
            <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Trans>slider_range</Trans>
            </Label>

            <Input
              id='min'
              name={`min[${index}]`}
              type='number'
              onWheel={(e: any) => e.target.blur()}
              dir='true'
              maxLength='3'
              errorName={errors?.min && errors.min[index]}
              min='1'
              width={'optionsWidth'}
              onChange={(e2: any) => {
                setMin(parseInt(e2?.target.value));
                handleInputChange(e2, index, undefined, 'form');
              }}
              ref={register({
                required: true,
                min: 1,
              })}
              placeholder={t('min')}
              defaultValue={value.scale?.min}
            />

            <Input
              id='max'
              name={`max[${index}]`}
              type='number'
              onWheel={(e: any) => e.target.blur()}
              dir='true'
              maxLength='3'
              min='1'
              width={'optionsWidth'}
              errorName={errors?.max && errors.max[index]}
              onChange={(e3: any) => {
                setMax(parseInt(e3?.target.value));
                handleInputChange(e3, index, undefined, 'form');
              }}
              placeholder={t('max')}
              ref={register({
                required: true,
                min: 1,
              })}
              defaultValue={value.scale?.max}
            />
            <Input
              id='step'
              name={`step[${index}]`}
              min='1'
              type='number'
              onWheel={(e: any) => e.target.blur()}
              dir='true'
              width={'optionsWidth'}
              errorName={errors?.step && errors.step[index]}
              onChange={(e4: Event) =>
                handleInputChange(e4, index, undefined, 'form')
              }
              maxLength='3'
              ref={register({
                required: true,
                min: 1,
                validate: (el: string) => {
                  return parseInt(el) < value?.scale?.max;
                },
              })}
              placeholder={t('step')}
              defaultValue={value.scale?.step}
            />
          </QuestionTypeScale>

          <QuestionTypeScale>
            <InfoLabel>
              <InfoIcon onClick={showDrawer} />
              <Trans>points</Trans>
            </InfoLabel>
            <Input
              id='min_points'
              name={`min_points[${index}]`}
              type='number'
              onWheel={(e: any) => e.target.blur()}
              maxLength='3'
              dir='true'
              min='0'
              width={'optionsWidth'}
              onChange={(e5: any) => {
                handleInputChange(e5, index, undefined, 'form');
              }}
              placeholder={t('min_points')}
              errorName={errors?.min_points && errors.min_points[index]}
              ref={register({
                required: true,
                min: 0,
              })}
              defaultValue={value.scale?.min_points}
            />

            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <Input
              id='max_points'
              name={`max_points[${index}]`}
              type='number'
              onWheel={(e: any) => e.target.blur()}
              dir='true'
              maxLength='3'
              min='0'
              width={'optionsWidth'}
              onChange={(e6: any) => {
                handleInputChange(e6, index, undefined, 'form');
              }}
              placeholder={t('max_points')}
              errorName={errors?.max_points && errors.max_points[index]}
              ref={register({
                required: true,
                min: 0,
              })}
              defaultValue={value.scale?.max_points}
            />

            <Drawer title='نظام النقاط' open={visible} setVisible={setVisible}>
              <DrawerBodyTitle>
                <Trans>drawer_scale_content</Trans>
              </DrawerBodyTitle>
              <ExampleSpan>
                <Trans>example</Trans>
              </ExampleSpan>
              <ScaleDetailsBox>
                <ExampleSpan>كم استغرق من الوقت لاستلام الطلب؟</ExampleSpan>
                <div
                  style={{
                    width: '109%',
                    margin: '12px 25px 20px -48px',
                  }}
                >
                  <Slider min={0} max={0} value={0} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Label style={{ fontSize: '12px' }}>10</Label>
                  <Label style={{ fontSize: '12px' }}>
                    <Trans>scale_points</Trans> : 0
                  </Label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Label style={{ fontSize: '12px' }}>100</Label>
                  <Label style={{ fontSize: '12px' }}>
                    <Trans>points</Trans>: 0
                  </Label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Label style={{ fontSize: '12px' }}>
                    "مقدار زيادة العدد بين نقاط القياس"
                  </Label>
                  <Label style={{ fontSize: '12px' }}>
                    <Trans>step</Trans> : 1
                  </Label>
                </div>
              </ScaleDetailsBox>
            </Drawer>
          </QuestionTypeScale>
          {errors.min && errors?.min[index]?.types?.required && (
            <Error>
              <Trans>required_error_message</Trans>
            </Error>
          )}
          {errors.min && errors?.min[index]?.types?.min && (
            <Error>
              <Trans>zero_error_message</Trans>
            </Error>
          )}
          {errors.step && errors?.step[index]?.types?.validate && (
            <Error>
              <Trans>min_error_message</Trans>
            </Error>
          )}
        </SliderWrapper>
      );
    }
  };

  const handleImageUploading = async (e: {
    file: { name: any };
    target: { files: (string | Blob)[] };
  }) => {
    setFileLoading(true);
    //@ts-ignore
    setFileName(e.target.files[0].name);
    const image_url = '/api/file-upload';
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('entity', 'templates');
    formData.append('file_type', 'files');
    formData.append('temp_path', 'false');

    await axiosApiInstance
      .post(image_url, formData)
      .then((res) => {
        setFileLoading(false);
        setShowImage(res.data);
        editableData.file = res.data.fullUrl;
      })
      .catch((err) => {
        setFileLoading(false);
        showNotification(NotificationStatus.Error, 'Error', err.message);
      });
  };

  const handleDeleteFile = () => {
    setShowImage(null);
    useStore.setState({
      evaluationDetailsData: { ...evaluationDetailsData, file: '' },
    });
  };

  const buttonCircleStyle = {
    position: 'absolute',
    marginRight: '-65px',
    backgroundColor: Colors.red,
    color: 'white',
    borderColor: Colors.red,
    display: 'block',
    alignSelf: 'flex-start',
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormTemplate style={{ margin: '0 auto' }}>
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>evaluation_template_name</Trans>
        </Label>
        <Input
          name='name'
          placeholder={t('placeholder')}
          type='text'
          dir='true'
          errorName={errors.name}
          style={{ width: '100%' }}
          ref={register({ required: true, maxLength: 50 })}
          onChange={(e: Event) => handleInputChange(e)}
          defaultValue={editableData.name}
        />
        {errors?.name?.types?.required && (
          <Error
            style={{
              direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
            }}
          >
            <Trans>evaluation_template_name_required</Trans>
          </Error>
        )}

        {errors?.name?.types?.maxLength && (
          <Error
            style={{
              direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
            }}
          >
            <Trans
              values={{
                count: 50,
              }}
            >
              maxlenght_error_message
            </Trans>
          </Error>
        )}
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>evaluation_template_description</Trans>
        </Label>
        <Input
          name='description'
          type='text'
          dir='true'
          style={{ width: '100%' }}
          errorName={errors.description}
          onChange={(e: Event) => handleInputChange(e)}
          ref={register({ required: true, maxLength: 200 })}
          defaultValue={editableData.description}
        />
        {errors?.description?.types?.required && (
          <Error
            style={{
              direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
            }}
          >
            <Trans>evaluation_template_description_required</Trans>
          </Error>
        )}
        {errors?.description?.types?.maxLength && (
          <Error
            style={{
              direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
            }}
          >
            <Trans
              values={{
                count: 200,
              }}
            >
              maxlenght_error_message
            </Trans>
          </Error>
        )}

        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>add_pdf_to_template</Trans>
        </Label>

        <ImageContainer>
          {fileLoading && (
            <div style={{ position: 'relative' }}>
              <ImgLoadingContainer active={false}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                  }}
                >
                  <FileUploadIcon />
                  <FileName>{fileName}</FileName>
                </div>
                <Progress
                  status='active'
                  percent={40}
                  showInfo
                  size={undefined}
                />
              </ImgLoadingContainer>
            </div>
          )}

          <label htmlFor='file' style={{ width: '100%' }}>
            {showImage ? (
              <div style={{ position: 'relative' }}>
                <ImgLoadingContainer active>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'flex-start',
                      }}
                    >
                      <FileUploadIcon />
                      <FileName>{fileName}</FileName>
                    </div>
                    <RedIconDelete onClick={() => handleDeleteFile()} />
                  </div>
                  <Progress
                    status='success'
                    percent={100}
                    showInfo
                    size={undefined}
                  />
                </ImgLoadingContainer>
              </div>
            ) : (
              i18n.language === 'ar' &&
              !editableData.file &&
              !fileLoading && (
                <LabelImag>
                  <Input
                    type='file'
                    id='file'
                    accept='.pdf'
                    name='file'
                    style={{ display: 'none' }}
                    onChange={(e: any) => handleImageUploading(e)}
                  />
                </LabelImag>
              )
            )}

            {editableData.file && !showImage && (
              <ImgLoadingContainer active>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      alignItems: 'flex-start',
                    }}
                  >
                    <FileUploadIcon />
                    <FileName>
                      <Trans>upload_template_pdf_summary</Trans>
                    </FileName>
                  </div>
                  <RedIconDelete onClick={() => handleDeleteFile()} />
                </div>
                <Progress
                  status='success'
                  percent={100}
                  showInfo
                  size={undefined}
                />
              </ImgLoadingContainer>
            )}
          </label>
        </ImageContainer>
      </FormTemplate>
      <SectionTitle>
        <Trans>evaluation_items</Trans>
      </SectionTitle>

      <div style={{ margin: '0 auto', width: '100%' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {evaluationDetailsData?.questions?.map(
                  (value: QuestionsProps, index: any) => (
                    <Draggable
                      key={value?.id}
                      draggableId={String(value?.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <SupContainer
                          key={value.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Button
                            shape={'circle'}
                            size={'large'}
                            style={buttonCircleStyle}
                            onClick={() =>
                              handleAddQuestionBlockOrMultiChoiceQuestion(index)
                            }
                          >
                            +
                          </Button>
                          <FormTemplate>
                            <QuestionsHeadContainer>
                              {renderTotalPointsAndPercentageForWeightTemplate(
                                value
                              )}
                              <div>
                                <QuestionNumber>{index + 1}</QuestionNumber>
                              </div>
                              <WeightWrapper>
                                <SwitchBtn
                                  className={value?.required}
                                  onChange={(value) =>
                                    handleOnChangeSwitchButton(value, index)
                                  }
                                  defaultChecked={value?.required}
                                />
                                {value.required ? (
                                  <SwitchLabel>
                                    <Trans>mandatory_switch_question</Trans>
                                  </SwitchLabel>
                                ) : (
                                  <SwitchLabel>
                                    <Trans>optional_switch_question</Trans>
                                  </SwitchLabel>
                                )}
                                {index === 0 ? (
                                  <></>
                                ) : (
                                  <DeleteIcon
                                    onClick={() =>
                                      handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                        index
                                      )
                                    }
                                  />
                                )}
                              </WeightWrapper>
                            </QuestionsHeadContainer>
                            <QuestionBodyContainer>
                              <Input
                                key={index}
                                id='question'
                                placeholder={t('question_head')}
                                name={`question[${index}]`}
                                type='text'
                                style={{ width: '100%' }}
                                dir='true'
                                errorName={
                                  errors?.question && errors.question[index]
                                }
                                onChange={(e: Event) =>
                                  handleInputChange(e, index, undefined, 'form')
                                }
                                ref={register({
                                  required: true,
                                  message: 'error message',
                                  maxLength: 120,
                                })}
                                defaultValue={value.question}
                              />
                              <QuestionDropdownContainer
                                language={i18n.language === 'ar' ? 'ar' : 'en'}
                              >
                                <SelectWrapper
                                  language={
                                    i18n.language === 'ar' ? 'ar' : 'en'
                                  }
                                >
                                  <Label
                                    language={
                                      i18n.language === 'ar' ? 'ar' : 'en'
                                    }
                                  >
                                    <Trans>focus_of_question_optional</Trans>
                                  </Label>

                                  <WrappedSelect
                                    options={question_categories}
                                    className={`question-focus-select[${value.id}]`}
                                    name={`question-focus-select[${value.id}]`}
                                    handleOnChange={(item: any) => {
                                      setMenuIsOpen({ [value.id]: false });
                                      handleQuestionCategoryChange(item, index);
                                    }}
                                    styles={customStyles}
                                    value={value}
                                    menuIsOpen={menuIsOpen[value.id]}
                                    onFocus={() =>
                                      setMenuIsOpen({
                                        [value.id]: true,
                                      })
                                    }
                                    onBlur={() =>
                                      setMenuIsOpen({ [value.id]: false })
                                    }
                                    handleKeydown={() => {
                                      if (!menuIsOpen[value.id])
                                        setMenuIsOpen({ [value.id]: true });
                                    }}
                                  />
                                </SelectWrapper>
                                <SelectWrapper
                                  language={
                                    i18n.language === 'ar' ? 'ar' : 'en'
                                  }
                                >
                                  <Label
                                    language={
                                      i18n.language === 'ar' ? 'ar' : 'en'
                                    }
                                  >
                                    <Trans>select_default_value</Trans>
                                  </Label>
                                  <Select
                                    defaultValue={
                                      value?.questionType?.id ? (
                                        value?.questionType?.id
                                      ) : (
                                        <SelectLabel
                                          language={
                                            i18n.language === 'ar' ? 'ar' : 'en'
                                          }
                                        >
                                          <Trans>select_default_value</Trans>
                                        </SelectLabel>
                                      )
                                    }
                                    className='question-type-select'
                                    onChange={(value: number, name: string) => {
                                      handleSelectOnChange(value, index, name);
                                    }}
                                  >
                                    {/* refactored   */}
                                    {questionTypes?.map(
                                      (type: questionTypesProps, i: number) => {
                                        return mockedArr.map((value, i) => {
                                          if (value.name === type.name) {
                                            return (
                                              <Option
                                                value={type.id}
                                                title={type.name}
                                                key={type.id}
                                              >
                                                <OptionWrapper
                                                  className={type.name}
                                                >
                                                  {value.icon}
                                                  <OptionValue>
                                                    {type.translation}
                                                  </OptionValue>
                                                </OptionWrapper>
                                              </Option>
                                            );
                                          }
                                        });
                                      }
                                    )}
                                  </Select>
                                </SelectWrapper>
                              </QuestionDropdownContainer>
                            </QuestionBodyContainer>
                            {errors.question &&
                              errors.question[index]?.type === 'required' && (
                                <Error
                                  style={{
                                    direction:
                                      i18n.language === 'ar' ? 'rtl' : 'ltr',
                                  }}
                                >
                                  <Trans>
                                    evaluation_template_question_required
                                  </Trans>
                                </Error>
                              )}
                            {errors.question &&
                              errors.question[index]?.types?.maxLength && (
                                <Error
                                  style={{
                                    direction:
                                      i18n.language === 'ar' ? 'rtl' : 'ltr',
                                  }}
                                >
                                  <Trans
                                    values={{
                                      count: 120,
                                    }}
                                  >
                                    maxlenght_error_message
                                  </Trans>
                                </Error>
                              )}

                            {editableData.type === 'points'
                              ? renderPointsTemplate(value, index)
                              : renderDefaultTemplate(value, index)}

                            {value.questionType?.id === 1 ||
                            value.questionType?.id === 8 ||
                            value.questionType?.id === 9 ? (
                              <>
                                <CheckboxWrapper>
                                  <Checkbox
                                    onChange={(event) =>
                                      onExtraImagesChange(event, index)
                                    }
                                    checked={value.extra_images}
                                  />
                                  <span className='checkbox-value'>
                                    <Trans>want_extra_images</Trans>
                                  </span>
                                </CheckboxWrapper>
                                <CheckboxWrapper>
                                  <Checkbox
                                    onChange={(event) =>
                                      onExtraVideosChange(event, index)
                                    }
                                    checked={value.extra_videos}
                                  />
                                  <span className='checkbox-value'>
                                    <Trans>want_extra_videos</Trans>
                                  </span>
                                </CheckboxWrapper>
                              </>
                            ) : (
                              <></>
                            )}
                          </FormTemplate>
                        </SupContainer>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <ButtonContainer>
        <Button
          // disabled={formState.isSubmitting}
          size={'large'}
          onClick={handleSubmit}
          loading={loading}
          type='primary'
        >
          <Trans>save</Trans>
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default React.memo(EditEvaluationTemplateForm);
