/** @format */

import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Span = styled.span`
  color: ${(props) => (props.color ? props.color : "white")};
  font-family: Famtree;
  font-size: 18px;
  font-weight: bold;
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 32px 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Hr = styled.hr`
  background-color: #e6e8e9;
  height: 0.7px;
  border: 0;
  width: 700px;
  margin-top: 29px;
`;

export const P = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin: 0 10px;
`;

export const ShoppersDataContainer: any = styled.div`
  border-radius: 14px;
  border: solid 0.7px ${Colors.light_gary_2};
  background-color: ${Colors.white};
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 63%;
  margin: 0 auto;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media print {
    width: 80%;
    .extra_with_slider {
      display: none;
    }
  }
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const Question = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
  margin-bottom: 65px;
`;

export const Answer = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  word-break: break-all;
  text-align: right;
`;

export const ChoicesAnswer = styled.div`
  text-align: center;
  min-width: 50%;
  padding: 15px 17px 15px 21px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: ${Colors.charcoal_grey};
  background-color: ${Colors.black_4};
  margin-bottom: 10px;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  margin-top: 15px;
  align-self: ${(props: { language: string }) =>
    props.language === "ar" ? "flex-end" : "flex-start"};
  word-break: break-all;
`;

export const AnswersCount = styled.span`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
  margin: ${(props: { language?: string }) =>
    props.language === "ar" ? "0 23% 0 0 " : "0 0 0 22%"};
`;

export const ClickableSpan = styled.span`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.dark_mint};
  margin-bottom: 23px;
  cursor: pointer;
`;

export const Answers = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

export const StyledPlayerWrapper = styled.div`
  margin: ${(props: { language?: string }) =>
    props.language === "ar" ? "0 0 0 10px" : "0 10px 0 0"};
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 12px;
  justify-content: flex-start;

  @media print {
    margin-bottom: 30px;
  }
`;

export const PageSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  margin: 0 auto;
  padding-top: 100px;
`;

export const OptionalValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 30%;
`;

export const OptionalValues = styled.div`
  width: 30%;
  margin-bottom: 20px;
`;

export const ImageWrapper = styled.div`
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? "0 0 10 10px" : "0 10px 15px 0"};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const RatingWrapper = styled.div`
`;

export const PdfWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props: { language: string }) =>
    props.language === "ar" ? "63%" : "14%"};
  align-self: flex-end;
  margin: 15px auto;
  span {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.charcoal_grey};
    margin-left: 12px;
  }
`;

export const ShopperDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnsweredOPtions = styled.div`
  text-align: center;
  width: 50%;
  padding: 15px 17px 15px 21px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: #344247;
  margin-bottom: 10px;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  margin-top: 15px;
  align-self: ${(props: { language: string }) =>
    props.language === "ar" ? "flex-end" : "flex-start"};
  border-radius: 10px;
  border: solid 0.5px #44c874;
  background-color: rgba(68, 200, 116, 0.12);
`;

export const ChartLabels = styled.h1`
  font-family: Famtree;
  font-size: 13px;
  font-weight: normal;
  color: #344247;
  text-align: right;
  margin: 0px;
`;

export const PointsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PercentageSpan = styled.span`
  margin: 0 15px 0 0;
  padding: 0 7px;
  border-radius: 9px;
  background-color: rgba(52, 66, 71, 0.15);
  font-family: Famtree;
  font-size: 13px;
  font-weight: normal;
  color: #344247;
  margin: 0 5px;
`;

export const PayMultiContainer = styled.div`
  background-color: #44c874;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const SelectedItems = styled.div`
  font-family: Famtree;
  padding: 11px;
  color: #fff;
  font-size: 18px;
  margin-top: 2px;
`;

export const VerticalLine = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  height: 35px;
  margin: 0 5px;
`;

export const PointsReviewWrapper = styled.section`
  padding: 20px;
  border-radius: 14px;
  border: solid 0.7px #ddd;
  background-color: #fff;
  width: 63%;
  margin: 0 auto;
  margin-bottom: 24px;
  position: relative;
  @media print {
    width: 110%;
  }
  @media (max-width: 991px) {
    padding: 10px;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
`;
export const PointsReview = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export const ChartWrapper = styled.section`
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 991px) {
  }
  @media (max-width: 767px) {
  }
`;

export const BranchRatingsSection = styled.section`
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  @media (max-width: 767px) {
    margin: 5px 0 20px 0;
    border-left: none;
    border-bottom: 1px solid #ddd;
  }
`;

export const PercentageLabels = styled.label`
  font-family: Famtree;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  color: #505d6f;
  position: absolute;
  bottom: 24%;
  @media (max-width: 767px) {
    bottom: 55%;
  }
`;

export const JobInfo = styled.div`
  padding: 16px 26px 27px 24px;
  border-radius: 16px;
  border: solid 0.5px #ccc;
  width: 698px;
  margin: 50px auto 30px auto;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const JobTitle = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  margin-bottom: 0;
`;

export const DateLabel = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(52, 66, 71, 0.38);
`;

export const DatesWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  justify-content: space-evenly;
`;

export const DateItem = styled.p`
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? "0px 0px 0px 25px" : "0px 25px 0px 0px"};
`;

export const DateText = styled.p`
  margin: 7px 0 0;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: #344247;
`;

export const HintWrapper = styled.div`
  width: 698px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TdreeTitle = styled.h1`
  font-family: Famtree;
  font-size: 25.6px;
  margin-right: 10px;
`;

export const Description = styled.p`
  font-family: Famtree;
  font-size: 14px;
`;

export const Th = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;

  text-align: right;
  padding: 8px;
`;

export const Td = styled.td`
  font-size: 13px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-right: 0px;
  font-family: Famtree;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  padding: 10px;

  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #dddddd;
`;

export const SAR = styled.span`
  text-align: left;
`;

export const NoResultWrapper = styled.div`
  margin-top: 70px;
`;

export const SpinnerContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #44c874;
  }
`;

export const PContainer = styled.div`
  width: 1275px;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  padding: 23px 16px 18px 17px;
  align-items: baseline;
  justify-content: flex-end;
`;

export const SummaryMessageWrapper = styled.div`
  width: 63%;
  margin: 0 auto 20px auto;
`;

export const SummaryMessage = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`;

export const BranchesList = styled.ul`
  margin: 10px 0;
`;

export const QuestionWithAnswer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 auto 11px auto;
  width: 63%;
  color: #000;
  .download-pdf {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  span {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.charcoal_grey};
    margin: 0 10px 0 10px;
    color: #000;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const ExcelWrapper = styled.div`
  margin: 0 20px 0 20px;
  cursor: pointer;
  span {
    color: #000;
    padding: 0;
    font-family: Famtree;
    margin: 0 4px 0 4px;
  }
`;

export const PdfCover = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  margin-bottom: 30px;
  justify-content: space-between;
  @media print {
    display: block;
  }
`;

export const StyledChartsWrapper = styled.div`
  width: ${(props: { width: string }) => (props.width ? props.width : "60%")};
  @media print {
    width: 50%;
  }
`;

export const ShopperCount = styled.span`
  margin: 4px 20px 0 20px;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #344247;
  white-space: nowrap;
  direction: rtl;
`;

export const ChoiceChart = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props: { language: string }) =>
    props.language === "ar" ? "row" : "row-reverse"};
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
`;

export const NoteWrapper = styled.div`
  width: 100%;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 10px;
  border-top: 1px solid #e8dada;
  word-break: break-all;
`;

export const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NoteHeaderText = styled.span`
  font-family: Famtree;
  color: #928a8a;
  margin: 0 5px 10px 5px;
`;

export const NoteText = styled.p`
  font-family: Famtree;
  color: #344247;
  margin-bottom: 0;
`;

export const ExtraHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const Pargraph = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: rgba(52, 66, 71, 0.54);
  text-align: center;
`;

export const PointFromText = styled.div`
  border-radius: 12px;
  background-color: rgba(52, 66, 71, 0.1);
  font-family: Famtree;
  font-size: 13px;
  font-weight: 300;
  color: #344247;
  padding: 5px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const PointSpan = styled.span`
  font-family: Famtree;
  margin: 0 3px;
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
`;
