/** @format */

import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Pargraph = styled.div`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  margin: 18px 0 34px;
`;

export const Span = styled.span`
  color: ${(props) => (props.color ? props.color : "white")};
  font-family: Famtree;
  font-size: 18px;
  font-weight: bold;
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 32px 0px;
`;

export const PContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  padding: 23px 16px 18px 17px;
  align-items: baseline;
  justify-content: flex-end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const Hr = styled.hr`
  background-color: #e6e8e9;
  height: 0.7px;
  border: 0;
  width: 100%;
  margin: 20px 0;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Label = styled.label`
  color: ${Colors.battleship_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin: 10px 0;
`;

export const Hint = styled.label`
  color: ${Colors.lite_red};
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-align: end;
  margin-top: 0px;
`;

export const SelectLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  line-height: 51px;
  padding: 0px;
`;

export const DescriptionFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const DemandsFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const SinglePaymentFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "ltr" : "rtl"};
`;

export const PaymentFormContainer = styled.form`
  display: flex;
  background-color: #fff;
  flex-direction: column;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "ltr" : "rtl"};
`;

export const StepsActions = styled.div`
  width: 166px;
  margin-bottom: 55px;
  margin-top: ${(props: any) =>
    props.className === "payment" ? "8px" : "47px"};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const SubmitContainer = styled.div`
  position: relative;
  .ant-space {
    position: absolute;
    left: 40%;
    top: 55%;
  }
`;

export const OptionValue = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-bottom: 0px;
  text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "start" : "end"};

  :hover {
    color: ${Colors.charcoal_grey};
  }
`;

export const DriversInformation = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const DriversLabel = styled.label`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
  margin-bottom: 10px;
`;

export const Section = styled.div`
  width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 900px) {
    width: 100%;
    display: unset;
    flex-direction: unset;
    justify-content: unset;
  }
`;

export const SupSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const OtherTerms = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: ${(props: { language: string }) =>
    props.language === "ar" ? "row-reverse" : "row"};
  margin-top: 35px;
`;

export const P = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin: 0 10px;
`;

export const Prefix = styled.span`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.mint_gray};
  position: absolute;
  top: 91px;
  left: 23px;
  transform: translateY(-50%);
  margin: 0px;
  width: 20%;
`;

export const TotalPayment = styled.p`
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  color: ${Colors.black};
  margin: 0px;
`;

export const TotalWithDiscount = styled.div`
  display: flex;
  align-items: center;
  .total-payment {
    margin: 0 15px;
  }
`;

export const TotalBeforeDiscount = styled.p`
  font-family: Famtree;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  color: #91999b;
  margin: 3px 0 0 0;

  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
`;

export const SpanPayment = styled.span`
  font-family: Famtree;
  font-size: 16px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_38};
  align-self: flex-end;
`;

export const DetailsSectionContainer = styled.div`
  width: 924px;
  border-radius: 24px;
  border: solid 1px rgba(52, 66, 71, 0.12);
  margin: 0 auto;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  margin-bottom: 50px;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: unset;
    border-radius: unset;
    border: unset;
    border-top: solid 1px rgba(52, 66, 71, 0.12);
  }
`;

export const Head = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  flex-wrap: wrap;
`;

export const Titles = styled.p`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.mint_gray};
  margin-bottom: 3px;
`;

export const NameLabel = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  overflow-wrap: break-word;
`;

export const TimeLabel = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
`;

export const StatusLabel = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.orange};
`;

export const DescriptionSection = styled.div`
  margin-top: 30px;
`;

export const InstructionLabel = styled.p`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.mint_gray};
  margin-top: 43px;
  margin-bottom: 35px;
`;

export const Astrics = styled.span`
  color: red;
`;

export const ShoppersDataContainer: any = styled.div`
  border-radius: 10px;
  border: solid 0.7px ${Colors.light_gary_2};
  background-color: ${Colors.white};
  display: flex;
  flex-direction: column;
  width: 63%;
  margin: 0 auto;
  padding-top: 15px;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const Question = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};

  @media (max-width: 900px) {
    text-align: center;
    font-size: 11px;
  }
`;

export const Answer = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  word-break: break-all;
  text-align: right;
`;

export const QuestionHead = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
`;

export const SpanNumbers = styled.div`
  width: 28px;
  height: 28px;
  background-color: ${Colors.dark_mint_2};
  color: ${Colors.charcoal_grey};
  border-radius: 50%;
  line-height: 31px;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
`;

export const ClickableSpan = styled.span`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.dark_mint};
  margin-bottom: 23px;
  cursor: pointer;
`;

export const Answers = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
  display: flex;
  flex-direction: column;

  word-break: break-all;
`;

export const PageSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  margin: 0 auto;
  font-family: Famtree;
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 auto 11px auto;
  width: 63%;
  color: #000;
  .download-pdf {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .download-after-scroll {
    background-color: #44c874;
    width: 100%;
    color: #44c874;
    border-radius: 20px;
    padding: 10px 0;
    text-align: center;
    margin: 25px 0;
    span {
      color: #fff;
      font-weight: bold;
    }
  }
  span {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.charcoal_grey};
    margin: 0 10px 0 10px;
    color: #000;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 20px 0 20px;
  button {
    color: #000;
    padding: 0;
    font-family: Famtree;
  }
`;

export const OptionalValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 30%;
`;

export const OptionalValues = styled.div`
  width: 30%;
  margin-bottom: 20px;
`;

export const ImageWrapper = styled.div`
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? "0 0 0 10px" : "0 10px 15px 0"};
`;

export const RatingWrapper = styled.div`
`;

export const PdfWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props: { language: string }) =>
    props.language === "ar" ? "63%" : "14%"};
  align-self: flex-end;
  margin: 15px auto;
  span {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.charcoal_grey};
    margin-left: 12px;
  }
`;

export const ShopperDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnsweredOPtions = styled.div`
  text-align: center;
  width: 50%;
  padding: 15px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: #344247;
  margin-bottom: 10px;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  margin-top: 15px;
  align-self: ${(props: { language: string }) =>
    props.language === "ar" ? "flex-end" : "flex-start"};
  border-radius: 10px;
  border: solid 0.5px #44c874;
  background-color: rgba(68, 200, 116, 0.12);
`;

export const ChartLabels = styled.h1`
  font-family: Famtree;
  font-size: 13px;
  font-weight: normal;
  color: #344247;
  margin: 0px;
`;

export const PointsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PointsDetails = styled.div`
  padding: 4px 10px 4px 9px;
  border-radius: 12px;
  background-color: rgba(52, 66, 71, 0.1);
  font-family: Famtree;
  font-size: 13px;
  font-weight: 300;
  color: #344247;
  text-align: right;

  @media print {
    width: 100%;
  }
`;

export const PercentageSpan = styled.span`
  margin: 0 15px 0 0;
  padding: 0 7px;
  border-radius: 9px;
  background-color: rgba(52, 66, 71, 0.15);
  font-family: Famtree;
  font-size: 13px;
  font-weight: normal;
  color: #344247;
`;

export const PayMultiContainer = styled.div`
  background-color: #44c874;
  display: flex;
  align-items: center;
  padding: 0 10px;
  .multiple-submit {
    margin: 0;
  }
`;

export const SelectedItems = styled.div`
  font-family: Famtree;
  padding: 11px;
  color: #fff;
  font-size: 18px;
  margin-top: 2px;
`;

export const VerticalLine = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  height: 35px;
  margin: 0 5px;
`;

export const PointsReviewWrapper = styled.section`
  padding: 10px;
  border-radius: 14px;
  border: solid 0.7px #ddd;
  background-color: #fff;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 24px;
  position: relative;
  @media print {
    width: 100%;
  }
  @media (max-width: 991px) {
    padding: 10px;
  }
  @media (max-width: 767px) {
  }
`;

export const BranchRatingsSection = styled.section`
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  @media (max-width: 767px) {
    margin: 5px 0 20px 0;
    border-left: none;
    border-bottom: 1px solid #ddd;
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
`;

export const PercentageLabels = styled.label`
  font-family: Famtree;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  color: #505d6f;
  position: absolute;
  bottom: 24%;
`;

export const JobInfo = styled.div`
  padding: 16px 26px 27px 24px;
  border-radius: 16px;
  border: solid 0.5px #ccc;
  width: 698px;
  margin: 50px auto 30px auto;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const JobTitle = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  margin-bottom: 0;
`;

export const DateLabel = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(52, 66, 71, 0.38);
`;

export const DatesWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const DateItem = styled.p`
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? "0px 0px 0px 25px" : "0px 25px 0px 0px"};
`;

export const DateText = styled.p`
  margin: 7px 0 0;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: #344247;
`;

export const HintWrapper = styled.div`
  width: 698px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PdfCover = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  margin-bottom: 30px;
  justify-content: space-between;
  @media print {
    display: block;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TdreeTitle = styled.h1`
  font-family: Famtree;
  font-size: 25.6px;
  margin-right: 10px;
`;

export const Description = styled.p`
  font-family: Famtree;
  font-size: 14px;
`;

export const Th = styled.th`
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;

  text-align: right;
  padding: 8px;
`;

export const Td = styled.td`
  font-size: 13px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-right: 0px;
  font-family: Famtree;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  padding: 10px;

  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #dddddd;
`;

export const SAR = styled.span`
  text-align: left;
`;

export const SearchAndFilterWrapper = styled.div`
  @media (max-width: 900px) {
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const ContnetContainer = styled.div`
  width: 50%;
  @media (max-width: 900px) {
    width: 92%;
  }
`;

export const SummaryWrapper = styled.div`
  @media print {
    display: none;
  }
`;

export const TabsWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const ListWrapper = styled.div`
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const BranchNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 63%;
  margin: auto;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  padding: 1% 4px;
  margin-bottom: 24px;
`;
