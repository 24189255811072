/** @format */

import React from 'react';
import EmptyTemplate from 'common/EmptyTemplate';
import { useQuery } from 'react-query';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import Spinner from 'common/LoadingIndecator';
import { LeftContainer, StyledContainer, Title } from 'pages/User/style';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

const TermsAndConditionsShoppers = () => {
  const { i18n } = useTranslation();

  const { data } = useQuery(
    ['termsAndConditions'],
    () => fetchGetRequests('api/pages/terms-conditions-shoppers'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return (
    <>
      <Helmet>
        <title>
          {i18n.language === 'ar'
            ? 'الشروط والأحكام للمتسوقين - تدري'
            : 'Terms and condition - Tdree'}
        </title>
        ‍
      </Helmet>
      <StyledContainer>
        <LeftContainer>
          <EmptyTemplate
            className='help'
            style={{
              padding: '20px',
            }}
          >
            {!data ? (
              <Spinner size='large' />
            ) : (
              <>
                <Title>{data?.response?.page?.name}</Title>

                <div
                  style={{
                    direction: 'rtl',
                    fontFamily: 'Famtree',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data?.response?.page?.text),
                  }}
                />
              </>
            )}
          </EmptyTemplate>
        </LeftContainer>
      </StyledContainer>
    </>
  );
};

export default TermsAndConditionsShoppers;
