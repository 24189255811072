/** @format */

import Colors from "../../infrastructure/utils/Colors";
import styled from "styled-components";
import { Button } from "antd";

// evaluation category
export const CategoryWrapper = styled.div`
  margin: 0 5px 5px 5px;
  background-color: #fff1ea;
  padding: 8px;
  border-radius: 10px;
  opacity: 0.77;
  display: inline-block;
  margin-bottom: 10px;
`;
export const Category = styled.p`
  color: #ee9c6c;
  font-size: 14px;
  margin-bottom: 0;
`;

// points review
export const PointsReviewWrapper = styled.section`
  padding: 10px;
  border-radius: 14px;
  border: solid 0.7px #ddd;
  background-color: #fff;
  width: 63%;
  margin: 0 auto;
  margin-bottom: ${(props: { mBot?: string }) =>
    props.mBot ? props.mBot : "24px"};
  position: relative;
  min-height: 5vh;
  @media print {
    width: 100%;
    display: block;
    margin-top: 0px;
    @page {
      margin: 20mm 5mm;
      size: auto;
    }
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
    .page-break {
      margin-top: 1rem;
      display: block;

      page-break-inside: avoid;
    }
  }
  @media (max-width: 900px) {
    padding: 10px;
    width: 95%;
  }
`;

export const BranchRatingsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 767px) {
    margin: 5px 0 20px 0;
  }
`;

export const PercentageLabels = styled.label`
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  color: #505d6f;
  position: absolute;
  bottom: 25%;
  font-family: Famtree;
  margin-top: 5px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
  @media print {
    font-family: source-code-pro;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    position: absolute;
    bottom: 25%;
    color: #505d6f;
  }
`;

export const PointLabel = styled.label`
  font-size: 14px;
  color: rgba(52, 66, 71, 0.54);
  position: absolute;
  bottom: 10%;
  font-family: Famtree;
  @media (max-width: 767px) {
    font-size: 10px;
  }
  @media print {
    font-size: 14px;
    color: rgba(52, 66, 71, 0.54);
    position: absolute;
    bottom: 10%;
    font-family: source-code-pro;
  }
`;

export const ChartLabels = styled.h1`
  font-size: 13px;
  font-weight: normal;
  color: #344247;
  margin: 0 0 10px 0;
  font-family: Famtree;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const VerticalLine = styled.div`
  width: 1px;
  min-height: 200px;
  margin: 0 8px 0 8px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #ccc;
  margin-top: 20px;
`;

// shoppers data
export const ShoppersContainer: any = styled.div`
  border-radius: 14px;
  border: solid 0.7px ${Colors.light_gary_2};
  background-color: ${Colors.white};
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  width: 63%;
  margin: 0 auto;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 900px) {
    width: 95%;
  }
`;
export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 900px) {
    width: 100%;
  }
`;

// question with answer
export const QuestionsAnswersWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  @media print {
    width: 95%;
  }
`;
export const LeftAnswers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const RightAnswers = styled.div`
  display: flex;
  align-items: baseline;
`;
export const PointFromText = styled.div`
  border-radius: 12px;
  background-color: rgba(52, 66, 71, 0.1);
  font-size: 13px;
  font-weight: 300;
  color: #344247;
  padding: 5px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%
`;
export const PercentageSpan = styled.span`
  margin: 0 15px 0 0;
  padding: 0 7px;
  border-radius: 9px;
  background-color: rgba(52, 66, 71, 0.15);
  font-size: 13px;
  color: #344247;
`;
export const PointSpan = styled.span`
  margin: 0 3px;
`;
export const Types = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const LinePrpgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const LinePrpgressPercentage = styled.span`
  color: rgba(0, 0, 0, 0.88);
  word-break: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.88);
  word-break: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.88);
  word-break: normal;
  font-size: 18px;
  font-weight: normal;
`;
export const Icon = styled.div`
  margin: 5px 0 0 0;
`;
export const QuestionTypeStyle = styled.div`
  font-size: 14px;
  color: ${Colors.charcoal_grey};
  @media (max-width: 900px) {
    font-size: 11px;
  }
`;
export const NumberStyle = styled.div`
  color: #344247;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;

  @media (max-width: 800px) {
    margin-left: unset;
  }
`;
export const Question = styled.p`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};

  @media (max-width: 900px) {
    text-align: right;
    font-size: 11px;
  }
`;

/* answer count  */
export const Count = styled.p`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 35px;
  margin-bottom: 35px;
  color: ${Colors.charcoal_grey_54};
  font-weight: normal;
  margin-bottom: 10px;
  color: #000;
  &::before {
    padding: 0 5px;
    content: "•   ";
  }
  @media (max-width: 900px) {
    text-align: right;
    font-size: 11px;
  }
`;

export const CountWrapper = styled.div`
  margin-bottom: 20px;
`;

/* answer chart  */
export const StyledChartsWrapper = styled.div`
  width: ${(props: { width?: string }) => (props.width ? props.width : "70%")};
  @media print {
    width: 50%;
  }
`;
export const AnswersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 5px 0;
  word-wrap: break-word;
`;
export const AttachmentWrapper = styled.div`
  margin: 10px 0;
`;
export const NotesWrapper = styled.div`
  margin: 10px 0;
`;

export const StyledPlayerWrapper = styled.div`
  margin: ${(props: { language?: string }) =>
    props.language === "ar" ? "0 0 0 10px" : "0 10px 0 0"};
`;
export const ImageWrapper = styled.div`
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? "0 0 0 10px" : "0 10px 15px 0"};
`;
export const Answers = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label`
  color: ${Colors.battleship_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
`;
export const StyledNotesButton = styled(Button)`
  height: 52px;
  margin: 0;
  padding: 16px 150px;
  border-radius: 9px;
  background-color: #f8f8f8;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #44c874;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    display: none;
  }
`;
export const Hr = styled.hr`
  background-color: rgb(221, 221, 221);
  height: 0.7px;
  border: 0;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ChoicesAnswer = styled.div`
  text-align: center;
  padding: 15px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: ${Colors.charcoal_grey};
  background-color: ${Colors.black_4};
  margin-bottom: 10px;
  margin-top: 15px;
  word-break: break-all;
`;

//  answered question
export const AnsweredOptions = styled.div`
  padding: 10px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: #344247;
  margin: 5px 0px 5px 0px;
  border-radius: 10px;
  background-color: rgba(68, 200, 116, 0.12);
  background-color: ${(props: { answer: string }) =>
    props.answer === "yes" ? "rgba(68, 200, 116, 0.12)" : Colors.black_4};
  display: flex;
  gap: 10px;
`;
export const AnsweredQuestionWrapper = styled.div`
  width: 50%;
`;
export const Answer = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  word-break: break-all;
  text-align: right;
`;
export const ExtraQuestionsWrapper = styled.div`
  width: 100%;
`;

// shopper information
export const ShoppersTitle = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-bottom: 0px;
  @media (max-width: 900px) {
    font-size: 14px;
  }
`;
export const ShoppersAddress = styled.span`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: #757575;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;
export const Info = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    align-items: center;
    width: 100%;
  }
  @media print {
    display: block;
    width: auto;
  }
`;
export const ShopperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 25%;
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    justify-content: start;
    .shopper-name {
      flex-direction: column;
      margin: 0 25px;
      width: auto;
    }
  }
  @media print {
    display: flex;
    width: 25%;
  }
`;
export const ShopperInformationRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 20px;
  margin: 0px auto;
  border-radius: 10px;
  border: solid 0.7px ${Colors.light_gary_2};
  width: 63%;
  @media (max-width: 900px) {
    width: 95%;
  }
  @media print {
    width: 100%;
    flex-direction: row;
    display: ${(props: { show_in_print: boolean }) =>
    !props.show_in_print ? "none" : "flex"};
  }
`;

export const ShopperCount = styled.span`
  margin: 0px 15px;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #344247;
  white-space: nowrap;
  direction: rtl;
`;

export const ChoiceChart = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 5px;
  margin-bottom: 5px;

  margin-bottom: 5px;
`;

export const ScaleChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  background-color: rgb(246 246 246);
  min-width: 70%;
  padding: 2% 4%;
  border-radius: 10px;
`;

export const Span = styled.span``;
