/** @format */

import React from 'react';
import {
  RightContainer,
  LeftContainer,
  P,
  Container,
  BackIcon,
  CancelIcon,
  StyledHeader as AntDHeader,
  StyledResponsiveHeader as AntDResponsiveHeader,
  StyledMenu as AntDMenu,
  IconWithLabel,
} from './style';
import { useNavigate } from 'react-router-dom';
import { IProps } from '../../infrastructure/interfaces/header.interface';
import DeleteIcon from '../../assets/svg/icon-delete';
import EditIcon from '../../assets/svg/edit-icon';
import { Col, Image, MenuProps, Row } from 'antd';
import { Title } from 'components/Dashboard';
import mainLogo from 'assets/svg/mainLogo.webp';
import LoginIcon from 'assets/svg/login-ic';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'common/Button';
import Modal from '../Modal';
import { StyledP } from '../Menu/style';
import { Desktop, Mobile, Tablet } from 'infrastructure/utils/responsive';
import storage from 'infrastructure/utils/storage';

export const CustomHeader = ({
  title,
  editPath,
  type,
  handleDeleteIcon,
  handleCancelJob,
  handleDuplicate,
  status,
  component,
  cloning,
  isDuplicateModalVisible,
  handleCancelDuplicateModal,
  showDuplicateModal,
  duplicateTitle,
  duplicateMessage,
}: IProps) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <Container
      component={component}
      language={i18n.language === 'ar' ? 'ar' : 'en'}
    >
      {type === 'withoutLeftContainer' ? (
        <RightContainer>
          <P language={i18n.language === 'ar' ? 'ar' : 'en'}>{title}</P>
          {i18n.language === 'ar' ? (
            <BackIcon
              className='fa fa-long-arrow-right'
              onClick={() => {
                navigate(-1);
              }}
            />
          ) : (
            <BackIcon
              className='fa fa-long-arrow-left'
              onClick={() => {
                navigate(-1);
              }}
            />
          )}
        </RightContainer>
      ) : (
        <>
          <RightContainer>
            <P language={i18n.language === 'ar' ? 'ar' : 'en'}>{title}</P>
            {i18n.language === 'ar' ? (
              <BackIcon
                className='fa fa-long-arrow-right'
                onClick={() => {
                  navigate(-1);
                }}
              />
            ) : (
              <BackIcon
                className='fa fa-long-arrow-left'
                onClick={() => {
                  navigate(-1);
                }}
              />
            )}
          </RightContainer>
          <LeftContainer>
            {cloning && (
              <>
                <IconWithLabel>
                  <Title onClick={showDuplicateModal}>
                    <Trans>duplicate_job</Trans>
                  </Title>
                  <i className='fa fa-clone' />
                </IconWithLabel>
                <Modal
                  title={<StyledP>{duplicateTitle}</StyledP>}
                  visible={isDuplicateModalVisible}
                  onOk={handleDuplicate}
                  onCancel={handleCancelDuplicateModal}
                >
                  {duplicateMessage}
                </Modal>
              </>
            )}
            {component === 'jobs' && status === 1 && (
              <IconWithLabel>
                <EditIcon
                  onClick={() => navigate(editPath)}
                  jobDetails='jobDetails'
                />
                <DeleteIcon onClick={handleDeleteIcon} />
                <CancelIcon
                  className='fa fa-times-circle'
                  onClick={handleCancelJob}
                />
              </IconWithLabel>
            )}
            {component === 'jobs' && status === 2 && (
              <CancelIcon
                className='fa fa-times-circle'
                onClick={handleCancelJob}
              />
            )}
          </LeftContainer>
        </>
      )}
    </Container>
  );
};

export const MainHeader = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    storage.setItem('i18nextLng', i18n.language);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <Button size='large' onClick={() => navigate('/register')}>
          <Trans>register_as_company</Trans>
        </Button>
      ),
      key: 'registeration',
    },
    {
      label: (
        <Button
          type='text'
          size='large'
          icon={<LoginIcon />}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            direction: 'rtl',
          }}
          onClick={() => navigate('/login')}
        >
          <Trans>login_as_company</Trans>
        </Button>
      ),
      key: 'login',
    },
    {
      label: (
        <>
          <span style={{ border: 'solid 1px #e4e4e4' }} />

          {i18n.language === 'en' ? (
            <Button type='text' onClick={() => changeLanguage('ar')}>
              العربية
            </Button>
          ) : (
            <Button type='text' onClick={() => changeLanguage('en')}>
              English
            </Button>
          )}
        </>
      ),
      key: 'language',
    },
  ];

  const mobileItems: MenuProps['items'] = [
    {
      label: (
        <Button type='text' onClick={() => navigate('/register')}>
          <Trans>register_as_company</Trans>
        </Button>
      ),
      key: 'registeration',
    },
    {
      label: (
        <Button
          type='text'
          icon={<LoginIcon />}
          onClick={() => navigate('/login')}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            direction: 'rtl',
          }}
        >
          <Trans>login_as_company</Trans>
        </Button>
      ),
      key: 'login',
    },
    {
      label:
        i18n.language === 'en' ? (
          <Button type='text' onClick={() => changeLanguage('ar')}>
            العربية
          </Button>
        ) : (
          <Button type='text' onClick={() => changeLanguage('en')}>
            English
          </Button>
        ),
      key: 'language',
    },
  ];
  return (
    <>
      <Desktop>
        <Row justify='center'>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <AntDHeader className='header-fixed'>
              <Row>
                <Col xl={4} lg={8} md={12} sm={20} xs={20}>
                  <Title id='title-button'>
                    <Image width={141} src={mainLogo} preview={false} />
                  </Title>
                </Col>
                <Col
                  xl={20}
                  lg={16}
                  md={12}
                  sm={4}
                  xs={4}
                  style={{ direction: 'ltr' }}
                >
                  <AntDMenu
                    theme='light'
                    mode='horizontal'
                    overflowedIndicator={<LoginIcon />}
                    items={items}
                  />
                </Col>
              </Row>
            </AntDHeader>
          </Col>
        </Row>
      </Desktop>

      <Tablet>
        <Row justify='center'>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <AntDHeader className='header-fixed'>
              <Row>
                <Col xl={4} lg={8} md={12} sm={20} xs={20}>
                  <Title id='title-button'>
                    <Image width={141} src={mainLogo} preview={false} />
                  </Title>
                </Col>
                <Col
                  xl={20}
                  lg={16}
                  md={12}
                  sm={4}
                  xs={4}
                  style={{ direction: 'ltr' }}
                >
                  <AntDMenu
                    theme='light'
                    mode='horizontal'
                    overflowedIndicator={<LoginIcon />}
                    items={items}
                  />
                </Col>
              </Row>
            </AntDHeader>
          </Col>
        </Row>
      </Tablet>
      <Mobile>
        <Row justify='center'>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <AntDResponsiveHeader className='header-fixed'>
              <Row>
                <Col xl={12} lg={12} md={12} sm={20} xs={20}>
                  <Title id='title-button'>
                    <Image width={83} src={mainLogo} preview={false} />
                  </Title>
                </Col>
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={4}
                  xs={4}
                  style={{ direction: 'ltr' }}
                >
                  <AntDMenu
                    theme='light'
                    mode='horizontal'
                    style={{
                      height: '80px',
                      background: 'unset',
                      alignItems: 'start',
                    }}
                    overflowedIndicator={<LoginIcon />}
                    items={mobileItems}
                  />
                </Col>
              </Row>
            </AntDResponsiveHeader>
          </Col>
        </Row>
      </Mobile>
    </>
  );
};
