/** @format */

import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'common/Button';
import DateTimePicker from 'common/DateTimePicker';
import { Input } from 'common/Input';
import { Select } from 'common/Select';
import { SwitchBtn } from 'common/Switch';
import { CompanyBranchDataProps } from 'infrastructure/interfaces/branches-form.interface';
import { JobsForm } from 'infrastructure/interfaces/jobs.interface';
import { Error } from '../../style';

import moment from 'moment';
import { Select as AntDSelect } from 'antd';
import {
  Label,
  SelectLabel,
  DemandsFormContainer,
  OptionValue,
  Hr,
  DriversInformation,
  DriversLabel,
  Section,
  SupSection,
  OtherTerms,
  P,
  StepsActions,
} from '../style';

const { Option }: any = AntDSelect;

const DemandsForm = ({
  branchListData,
  handleSelectOnChange,
  handleRangePickerOnchange,
  handleInputChange,
  handleOnChangeSwitchButton,
  handleSubmit,
  register,
  errors,
  formState,
  setValue,
  watch,
  current,
  prev,
  data,
  configrationList,
}: JobsForm) => {
  const { t, i18n } = useTranslation();
  const picker = watch('picker');

  useEffect(() => {
    window.scrollTo(0, 0);

    register('evluationPlaceSelect', {
      validate: (value: number) => value || '',
    });

    register('picker', {
      validate: (value: Array<string>) => value.length || '',
    });
  }, [register]);

  if (
    errors?.evluationPlaceSelect?.ref?.value === 0 ||
    errors?.picker?.ref?.value.length === 0
  ) {
    window.scrollTo(0, 0);
  }

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  };

  return (
    <DemandsFormContainer
      onSubmit={handleSubmit}
      language={i18n.language === 'ar' ? 'ar' : 'en'}
    >
      <Label>
        <Trans>evaluation_place</Trans>
      </Label>

      <Select
        id='evaluation-place'
        placeholder={
          <SelectLabel>
            <Trans>evaluation_place</Trans>
          </SelectLabel>
        }
        className='full-width'
        mode='multiple'
        onChange={(
          value: number,
          item: {
            defaultValue: {
              id: number;
              name: string;
              description: string;
              title: string;
            };
          }
        ) => {
          handleSelectOnChange(value, item);
          setValue('evluationPlaceSelect', value);
        }}
        errorStyle={errors.evluationPlaceSelect}
        filterOption={(
          inputValue: string,
          option: { children: { props: { children: string } } }
        ) =>
          option.children.props.children
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        }
      >
        {branchListData?.map((item: CompanyBranchDataProps) => {
          return (
            <Option key={item.id} title='branch_id' value={item.id}>
              <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                {item.name}
              </OptionValue>
            </Option>
          );
        })}
      </Select>

      {errors.evluationPlaceSelect && (
        <Error
          style={{
            direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
          }}
        >
          <Trans>required_error_message</Trans>
        </Error>
      )}
      <Hr />

      <Label>
        <Trans>evaluation_dates</Trans>
      </Label>
      <DateTimePicker
        disabledDate={disabledDate}
        defaultValue={''}
        onChange={(data: any, dateString: any) => {
          handleRangePickerOnchange(data, dateString);
          setValue('picker', [...picker, ...dateString]);
        }}
        errorStyle={errors?.picker}
      />
      <br />
      {errors?.picker && (
        <Error
          style={{
            direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
          }}
        >
          <Trans>required_error_message</Trans>
        </Error>
      )}
      <Hr />

      <DriversInformation>
        <DriversLabel>
          <Trans>drivers_information</Trans>
        </DriversLabel>

        <Section>
          <SupSection>
            <Label>
              <Trans>drivers_number</Trans>
            </Label>
            <Input
              width='jobs'
              type='number'
              maxLength='2'
              name='shoppers_count'
              placeholder={t('drivers_number')}
              onChange={(e: Event) => handleInputChange(e)}
              errorName={errors.shoppers_count}
              ref={register({
                required: true,
                pattern: /^[0-9]*$/,
              })}
              className={'jobsinput'}
              onWheel={(e: any) => e.target.blur()}
            />
            {errors?.shoppers_count?.types?.required && (
              <Error
                style={{
                  direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                }}
              >
                <Trans>required_error_message</Trans>
              </Error>
            )}
            {errors?.shoppers_count?.types?.pattern && (
              <Error
                style={{
                  direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                }}
              >
                <Trans>accepts_only_numbers</Trans>
              </Error>
            )}
          </SupSection>

          <SupSection>
            <Label>
              <Trans>age</Trans>
            </Label>
            <Select
              defaultValue={
                <SelectLabel>
                  <Trans>doesn't_matter</Trans>
                </SelectLabel>
              }
              className={'jobs'}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option title='age_group_id' defaultValue={"doesn't_matter"}>
                <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                  {t("doesn't_matter")}
                </OptionValue>
              </Option>
              {configrationList?.ages?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='age_group_id' value={item.id}>
                      <OptionValue
                        language={i18n.language === 'ar' ? 'ar' : 'en'}
                      >
                        {item.name}
                      </OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>
        </Section>

        <Section>
          <SupSection>
            <Label>
              <Trans>gender</Trans>
            </Label>
            <Select
              defaultValue={
                <SelectLabel>
                  <Trans>doesn't_matter</Trans>
                </SelectLabel>
              }
              className={'jobs'}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='gender'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                  {t("doesn't_matter")}
                </OptionValue>
              </Option>
              {[
                { id: 'male', name: t('male') },
                { id: 'female', name: t('female') },
              ].map((item: { id: string; name: string }, index: number) => {
                return (
                  <Option key={index} title='gender' value={item.id}>
                    <OptionValue
                      language={i18n.language === 'ar' ? 'ar' : 'en'}
                    >
                      {item.name}
                    </OptionValue>
                  </Option>
                );
              })}
            </Select>
          </SupSection>

          <SupSection>
            <Label>
              <Trans>education</Trans>
            </Label>
            <Select
              defaultValue={
                <SelectLabel>
                  <Trans>doesn't_matter</Trans>
                </SelectLabel>
              }
              className={'jobs'}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='education_id'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                  {t("doesn't_matter")}
                </OptionValue>
              </Option>
              {configrationList?.educations?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='education_id' value={item.id}>
                      <OptionValue
                        language={i18n.language === 'ar' ? 'ar' : 'en'}
                      >
                        {item.name}
                      </OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>
        </Section>

        <Section>
          <SupSection>
            <Label>
              <Trans>skills</Trans>
            </Label>
            <Select
              placeholder={
                <SelectLabel style={{ alignSelf: 'flex-start' }}>
                  <Trans>doesn't_matter</Trans>
                </SelectLabel>
              }
              className={'jobs'}
              mode='multiple'
              allowClear={true}
              showArrow={true}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='skills'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                  {t("doesn't_matter")}
                </OptionValue>
              </Option>
              {configrationList?.skills?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='skills' value={item.name}>
                      <OptionValue
                        language={i18n.language === 'ar' ? 'ar' : 'en'}
                      >
                        {item.name}
                      </OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>

          <SupSection>
            <Label>
              <Trans>experiences</Trans>
            </Label>
            <Select
              defaultValue={
                <SelectLabel>
                  <Trans>doesn't_matter</Trans>
                </SelectLabel>
              }
              className={'jobs'}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
              errorStyle={errors.experiencesSelect}
            >
              <Option
                title='experience_id'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                  {t("doesn't_matter")}
                </OptionValue>
              </Option>
              {configrationList?.experiences?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='experience_id' value={item.id}>
                      <OptionValue
                        language={i18n.language === 'ar' ? 'ar' : 'en'}
                      >
                        {item.name}
                      </OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>
        </Section>

        <Section>
          <SupSection>
            <Label>
              <Trans>languages</Trans>
            </Label>
            <Select
              placeholder={
                <SelectLabel>
                  <Trans>doesn't_matter</Trans>
                </SelectLabel>
              }
              className={'jobs'}
              mode='multiple'
              allowClear={true}
              showArrow={true}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='languages'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                  {t("doesn't_matter")}
                </OptionValue>
              </Option>
              {configrationList?.languages?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='languages' value={item.name}>
                      <OptionValue
                        language={i18n.language === 'ar' ? 'ar' : 'en'}
                      >
                        {item.name}
                      </OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>

          <SupSection>
            <Label>
              <Trans>nationality</Trans>
            </Label>
            <Select
              defaultValue={
                <SelectLabel>
                  <Trans>doesn't_matter</Trans>
                </SelectLabel>
              }
              className={'jobs'}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='nationality_id'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue language={i18n.language === 'ar' ? 'ar' : 'en'}>
                  {t("doesn't_matter")}
                </OptionValue>
              </Option>
              {configrationList?.nationalities?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option
                      key={item.id}
                      title='nationality_id'
                      value={item.id}
                    >
                      <OptionValue
                        language={i18n.language === 'ar' ? 'ar' : 'en'}
                      >
                        {item.name}
                      </OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>
        </Section>

        <OtherTerms language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <P>
            <Trans>the_shopper_will_pay_for_something!</Trans>
          </P>
          <SwitchBtn onChange={(value) => handleOnChangeSwitchButton(value)} />
        </OtherTerms>

        {data.extra_pay && (
          <Section>
            <SupSection>
              <Label>
                <Trans>extra_item_description</Trans>
              </Label>
              <Input
                name='extra_item'
                onChange={(e: Event) => handleInputChange(e)}
                errorName={errors.extra_item}
                ref={register({ required: true })}
                width='extraItem'
                placeholder={t('extra_item_placeholder')}
                className={'jobsinput'}
              />
              {errors.extra_item && (
                <Error
                  style={{
                    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                  }}
                >
                  <Trans>required_error_message</Trans>
                </Error>
              )}
            </SupSection>
            <SupSection>
              <Label>
                <Trans>price_in_sar</Trans>
              </Label>
              <Input
                placeholder={t('sar')}
                width='price'
                type='number'
                maxLength='3'
                name='extra_item_price'
                onChange={(e: Event) => handleInputChange(e)}
                errorName={errors.extra_item_price}
                ref={register({
                  required: true,
                  min: 0,
                })}
                onWheel={(e: any) => e.target.blur()}
              />
              {errors?.extra_item_price?.types?.required && (
                <Error
                  style={{
                    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                  }}
                >
                  <Trans>required_error_message</Trans>
                </Error>
              )}

              {errors?.extra_item_price?.types?.min && (
                <Error
                  style={{
                    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                  }}
                >
                  <Trans>min_0</Trans>
                </Error>
              )}
            </SupSection>
          </Section>
        )}
      </DriversInformation>

      <StepsActions
        style={{
          display: 'flex',
          gap: '0 20px',
          flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row',
        }}
      >
        <Button
          id='next-button'
          type='primary'
          onClick={handleSubmit}
          disabled={formState.isSubmitting}
        >
          <Trans>next</Trans>
        </Button>

        {current > 0 && (
          <Button onClick={prev}>
            <Trans>previous</Trans>
          </Button>
        )}
      </StepsActions>
    </DemandsFormContainer>
  );
};

export default DemandsForm;
