/** @format */

import React, { useState } from 'react';
import { Col, Form, Image, Row } from 'antd';
import { MainHeader } from 'common/Header';
import webMockup from 'assets/svg/web-mockup.webp';
import mockupMob from 'assets/svg/mockup-mob.webp';
import webMockupResponsive from 'assets/images/web-mockup-responsive.webp';
import mockupMobileResponsive from 'assets/images/mockup-mob-responsive.webp';
import girlImg from 'assets/images/girl-img-responsive.webp';
import img from 'assets/svg/img.webp';
import ProfileIcon from 'assets/svg/profile-icon';
import TaskIcon from 'assets/svg/task-icon';
import StarIcon from 'assets/svg/star-icon';
import SmileysIcon from 'assets/svg/smileys-icon';
import { Player } from 'common/Player';
import { Button } from 'common/Button';
import ArrowLeft from 'assets/svg/arrow-left';
import InstgramIcon from 'assets/svg/instagram';
import WhatsappIcon from 'assets/svg/whatsapp';
import { useNavigate } from 'react-router-dom';
import TwitterIcon from 'assets/svg/twitter';
import StarIconResponsive from 'assets/svg/star-responsive';
import TaskIconResponsive from 'assets/svg/task-responsive';
import ProfileIconResponsive from 'assets/svg/profile-responsive';
import SmilyesIconResponsive from 'assets/svg/smileys-responsive';
import InstgramResponsiveIcon from 'assets/svg/instagram-responsive';
import WhatsappResponsiveIcon from 'assets/svg/whatsapp-responsive';
import TwitterResponsiveIcon from 'assets/svg/twitter-responsive';
import {
  Section,
  SectionTitle,
  TitleSpan,
  SectionParghraph,
  IconsWithContent,
  SectionParghraphResponsive,
  VideoWrapper,
  VideoWrapperResponsive,
  TdreeSection,
  TdreeTitleSpan,
  WebMockupImageWrapper,
  TdreeSectionResponsive,
  WebMockupImageWrapperResponsive,
  MockupMobileAndSocialLinksWrapperResponsive,
  DownloadTdreeText,
  RegisterNowSectionResponsive,
  FooterCopyWrites,
  FooterContactUs,
  FooterResponsive,
  FooterResponsiveCopyWrites,
  StyledText,
  StyledTextResponsive,
  Footer,
  RectangleContainer,
  StyledLink,
  PageWrapper,
  StoreBtnWrapper,
  Banner,
  StyledTitle,
  StyledButton,
} from './styles';
import LinkedinIcon from 'assets/svg/linkedin';
import LinkedinResponsive from 'assets/svg/linkedin-responsive';
import { Trans, useTranslation } from 'react-i18next';
import mainLogo from 'assets/svg/mainLogo.webp';
import GooglePlayIcon from 'assets/svg/google-play-logo-wine';
import AppStoreIcon from 'assets/svg/apple-btn';
import { axiosApiInstance } from 'infrastructure/utils/api';
import {
  showNotification,
  NotificationStatus,
} from 'infrastructure/helpers/showNotifications';
import RequestConsultationForm from './requestConsultationForm';
import Modal from 'common/Modal';
import { isValidUrl } from 'infrastructure/helpers/validateURL';
import { useMediaQuery } from 'react-responsive';

const Landing = () => {
  const [isConsultationModalVisible, setIsConsultationModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();

  const isMobile = window.matchMedia('(max-width: 400px)').matches;
  const isDesktop = useMediaQuery({ minWidth: 1224 });

  const handleOpenConsultationModal = () => {
    setIsConsultationModalVisible(true);
  };

  const handleCloseConsultationModal = () => {
    setIsConsultationModalVisible(false);
  };

  const handleModalSubmit = (values) => {
    setLoading(true);
    axiosApiInstance
      .post('api/consultation', values)
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t('done'),
          t('done_message')
        );
        form.resetFields();
        setIsConsultationModalVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          ' ',
          err.response.data.message
        );
      });
  };

  // isValidUrl
  const googlePlayURL =
    'https://play.google.com/store/apps/details?id=com.tdree';
  const tdreeVideo = 'https://www.youtube.com/watch?v=ZivJSRq03Nk';
  const appleAppURL = 'https://apps.apple.com/eg/app/tdree/id1558676299';

  return (
    <PageWrapper language={i18n.language === 'ar' ? 'ar' : 'en'}>
      <MainHeader />

      <Banner>
        <div style={{ marginBottom: '30px', textAlign: 'center' }}>
          <StyledTitle style={{ lineHeight: '0px' }}>
            <Trans>banner_text</Trans>
          </StyledTitle>
          <StyledTitle>
            <Trans>banner_sub_text</Trans>
          </StyledTitle>
        </div>
        <StyledButton
          onClick={handleOpenConsultationModal}
          size='large'
          style={{ height: '48px' }}
        >
          <Trans>request_consultation</Trans>
        </StyledButton>
        <Modal
          title={t('request_consultation')}
          visible={isConsultationModalVisible}
          onCancel={handleCloseConsultationModal}
          loading={loading}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                handleModalSubmit(values);
              })
              .catch(() => {
                console.error('Validate Failed');
              });
          }}
        >
          <RequestConsultationForm form={form} />
        </Modal>
      </Banner>
      <Section isMobile={isMobile}>
        {i18n.language === 'ar' ? (
          <SectionTitle isMobile={isMobile}>
            كيف يعمل <TitleSpan>تدري</TitleSpan>
          </SectionTitle>
        ) : (
          <SectionTitle isMobile={isMobile}>
            How <TitleSpan>Tdree</TitleSpan> Works
          </SectionTitle>
        )}
        <SectionParghraph isMobile={isMobile}>
          <Trans>tdree_works_01</Trans>
        </SectionParghraph>
        <SectionParghraph isMobile={isMobile}>
          <Trans>tdree_works_02</Trans>
        </SectionParghraph>
        <SectionParghraph isMobile={isMobile}>
          <Trans>tdree_works_03</Trans>
        </SectionParghraph>
        {i18n.language === 'en' ? (
          <SectionParghraph isMobile={isMobile}>
            The results are displayed as soon as the mystery shopper
            accomplishes the task in order to allow the establishment to view
            the assessment results.
          </SectionParghraph>
        ) : (
          ''
        )}
      </Section>
      {isDesktop ? (
        <>
          {/* tdree features section*/}
          <Section row>
            <IconsWithContent>
              <SmileysIcon />
              <SectionParghraph width style={{ marginTop: '10px' }}>
                <Trans>tdree feature_01</Trans>
              </SectionParghraph>
            </IconsWithContent>
            <IconsWithContent>
              <StarIcon />
              <SectionParghraph width>
                <Trans>tdree feature_02</Trans>
              </SectionParghraph>
            </IconsWithContent>
            <IconsWithContent>
              <TaskIcon />
              <SectionParghraph width>
                <Trans>tdree feature_03</Trans>
              </SectionParghraph>
            </IconsWithContent>
            <IconsWithContent>
              <ProfileIcon />
              <SectionParghraph width>
                <Trans>tdree feature_04</Trans>
              </SectionParghraph>
            </IconsWithContent>
          </Section>
          {/* tdree video section*/}
          <VideoWrapper>
            <Player url={isValidUrl(tdreeVideo)} />
          </VideoWrapper>
          {/* tdree for companies section */}
          <TdreeSection>
            <WebMockupImageWrapper>
              <img
                src={webMockup}
                width='100%'
                height='auto'
                alt='web-mockup'
              />
            </WebMockupImageWrapper>
            <Col span={12}>
              <TdreeTitleSpan font>
                <Trans>tdree_for_corporates</Trans>
              </TdreeTitleSpan>
              <SectionParghraph font>
                <Trans>can_easily_monitor_the_quality</Trans>
              </SectionParghraph>
              <SectionParghraph>
                <Trans>tdree_faster_assessment</Trans>
              </SectionParghraph>
              <br />
              <SectionParghraph>
                <Trans>geographical_coverage</Trans>
              </SectionParghraph>
              <br />
              <SectionParghraph>
                <Trans>accuracy_of_the_reports</Trans>
              </SectionParghraph>
              <br />
              <SectionParghraph>
                <Button size='large' onClick={() => navigate('/register')}>
                  <Trans>register_as_company_now</Trans>
                </Button>
              </SectionParghraph>
            </Col>
          </TdreeSection>
          {/* register now  section */}
          <TdreeSection registerSection>
            <RectangleContainer>
              <Image src={img} preview={false} width={510} />
              <StyledText>
                <Trans>register_now_and_take_benefits</Trans>
                <br />
                <StyledLink href='/login'>
                  <ArrowLeft /> <Trans>signup_now</Trans>
                </StyledLink>
              </StyledText>
            </RectangleContainer>
          </TdreeSection>
          {/* tdree app section */}
          <Section row>
            <Image preview={false} src={mockupMob} width={260} height='auto' />
            <Col span={12}>
              <TdreeTitleSpan font>
                <Trans>tdree_application</Trans>
              </TdreeTitleSpan>
              <SectionParghraph font>
                <Trans>you_can_earn</Trans>
              </SectionParghraph>
              <SectionParghraph>
                <Trans>accomplish_available_tasks</Trans>
              </SectionParghraph>
              <br />
              <SectionParghraph>
                <Trans>register_download_tdree</Trans>
              </SectionParghraph>
              <br />
              <StoreBtnWrapper>
                <Button
                  size='large'
                  type='primary'
                  href={isValidUrl(googlePlayURL)}
                  icon={<GooglePlayIcon />}
                  style={{
                    backgroundColor: '#000',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  Google Play
                </Button>

                <Button
                  size='large'
                  type='primary'
                  href={isValidUrl(appleAppURL)}
                  icon={<AppStoreIcon />}
                  style={{
                    backgroundColor: '#000',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  App Store
                </Button>
              </StoreBtnWrapper>
            </Col>
          </Section>
          {/* footer section */}

          <Footer>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Image width={141} height='auto' src={mainLogo} preview={false} />

              <FooterCopyWrites>
                <Trans>all_rights_reserved_information</Trans>
              </FooterCopyWrites>
            </Col>

            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: '50px',
              }}
            >
              <FooterContactUs>
                <Trans>contact_us</Trans>
              </FooterContactUs>
              <FooterContactUs>+966501325644</FooterContactUs>
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: '0px',
              }}
            >
              <Row>
                <InstgramIcon />
                <WhatsappIcon /> <TwitterIcon /> <LinkedinIcon />
              </Row>
            </Col>
          </Footer>
        </>
      ) : (
        <>
          <Section row isMobile={isMobile}>
            <IconsWithContent isMobile={isMobile}>
              <TaskIconResponsive />
              <SectionParghraphResponsive>
                <Trans>tdree feature_01</Trans>
              </SectionParghraphResponsive>
            </IconsWithContent>
            <IconsWithContent isMobile={isMobile}>
              <ProfileIconResponsive />
              <SectionParghraphResponsive>
                <Trans>tdree feature_02</Trans>
              </SectionParghraphResponsive>
            </IconsWithContent>
          </Section>
          <Section row isMobile={isMobile}>
            <IconsWithContent isMobile={isMobile}>
              <SmilyesIconResponsive />
              <SectionParghraphResponsive style={{ marginTop: '3px' }}>
                <Trans>tdree feature_03</Trans>
              </SectionParghraphResponsive>
            </IconsWithContent>

            <IconsWithContent isMobile={isMobile}>
              <StarIconResponsive />
              <SectionParghraphResponsive>
                <Trans>tdree feature_04</Trans>
              </SectionParghraphResponsive>
            </IconsWithContent>
          </Section>
          <VideoWrapperResponsive>
            <Player url={isValidUrl(tdreeVideo)} />
          </VideoWrapperResponsive>
          <TdreeSectionResponsive>
            <WebMockupImageWrapperResponsive>
              <img
                src={webMockupResponsive}
                width={'100%'}
                alt='web-mockup-res'
              />
            </WebMockupImageWrapperResponsive>
            <TdreeTitleSpan isMobile={isMobile}>
              <Trans>tdree_for_corporates</Trans>
            </TdreeTitleSpan>
            <SectionParghraphResponsive font>
              <Trans>can_easily_monitor_the_quality</Trans>
            </SectionParghraphResponsive>
            <SectionParghraphResponsive>
              <Trans>tdree_faster_assessment</Trans>
            </SectionParghraphResponsive>
            <br />
            <SectionParghraphResponsive>
              <Trans>geographical_coverage</Trans>
            </SectionParghraphResponsive>
            <br />

            <SectionParghraphResponsive>
              <Trans>accuracy_of_the_reports</Trans>
            </SectionParghraphResponsive>
            <br />
            <SectionParghraphResponsive>
              <Button onClick={() => navigate('/register')}>
                <Trans>register_as_company_now</Trans>
              </Button>
            </SectionParghraphResponsive>
          </TdreeSectionResponsive>
          <Section isMobile={isMobile}>
            <TdreeTitleSpan font isMobile={isMobile}>
              <Trans>tdree_aplication</Trans>
            </TdreeTitleSpan>
            <SectionParghraphResponsive font>
              <Trans>you_can_earn</Trans>
            </SectionParghraphResponsive>
            <SectionParghraphResponsive>
              <Trans>accomplish_available_tasks</Trans>
            </SectionParghraphResponsive>
            <MockupMobileAndSocialLinksWrapperResponsive>
              <Image
                preview={false}
                src={mockupMobileResponsive}
                style={{
                  position: 'relative',
                  bottom: '-2em',
                  width: '182px',
                  height: '236px',
                }}
              />
              <Col>
                <DownloadTdreeText>
                  <Trans>register_download_tdree</Trans>
                </DownloadTdreeText>
                <Button
                  size='large'
                  type='primary'
                  href={isValidUrl(googlePlayURL)}
                  icon={<GooglePlayIcon />}
                  style={{
                    backgroundColor: '#000',
                    display: 'flex',
                    gap: '0 10px',
                    flexDirection: 'row-reverse',
                    margin: '10px',
                    justifyContent: 'center',
                  }}
                >
                  Google Play
                </Button>

                <Button
                  size='large'
                  type='primary'
                  href={isValidUrl(appleAppURL)}
                  icon={<AppStoreIcon />}
                  style={{
                    backgroundColor: '#000',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    margin: '10px',
                    gap: '0 10px',
                    justifyContent: 'center',
                  }}
                >
                  App Store
                </Button>
              </Col>
            </MockupMobileAndSocialLinksWrapperResponsive>
          </Section>
          <TdreeSectionResponsive>
            <Image src={girlImg} preview={false} />
            <RegisterNowSectionResponsive>
              <StyledTextResponsive>
                <Trans>register_now_and_take_benefits</Trans>
                <br />
                <StyledLink href='/login'>
                  <ArrowLeft /> <Trans>signup_now</Trans>
                </StyledLink>
              </StyledTextResponsive>
            </RegisterNowSectionResponsive>
          </TdreeSectionResponsive>
          <FooterResponsive>
            <Image width={85} height='auto' src={mainLogo} preview={false} />
            <FooterContactUs isMobile>
              <Trans>contact-us</Trans>
            </FooterContactUs>
            <Row
              style={{
                marginTop: '18px',
                justifyContent: 'space-between',
              }}
            >
              <Row>
                <InstgramResponsiveIcon />
                <WhatsappResponsiveIcon />
                <TwitterResponsiveIcon />
                <LinkedinResponsive />
              </Row>
              <FooterContactUs isMobile>+966501325644</FooterContactUs>
            </Row>
            <FooterResponsiveCopyWrites>
              <Trans>all_rights_reserved_information</Trans>
            </FooterResponsiveCopyWrites>
          </FooterResponsive>
        </>
      )}
    </PageWrapper>
  );
};

export default Landing;
