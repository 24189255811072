/** @format */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'common/Button';
import DateTimePicker from 'common/DateTimePicker';
import { Input } from 'common/Input';
import { Select } from 'common/Select';
import { SwitchBtn } from 'common/Switch';
import { CompanyBranchDataProps } from 'infrastructure/interfaces/branches-form.interface';
import { JobsForm } from 'infrastructure/interfaces/jobs.interface';
import { Error } from '../../style';
import { Select as AntDSelect } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

import {
  Label,
  DescriptionFormContainer,
  OptionValue,
  Hr,
  DriversInformation,
  DriversLabel,
  Section,
  SupSection,
  OtherTerms,
  P,
  StepsActions,
  Astrics,
} from '../style';

const { Option }: any = AntDSelect;

const DemandsForm = ({
  branchListData,
  handleSelectOnChange,
  handleRangePickerOnchange,
  handleInputChange,
  handleOnChangeSwitchButton,
  handleSubmit,
  register,
  errors,
  formState,
  current,
  prev,
  data,
  configrationList,
}: JobsForm) => {
  const { t, i18n } = useTranslation();

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day');
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dateFormat = 'YYYY/MM/DD HH:mm';

  return (
    <DescriptionFormContainer
      language={i18n.language === 'ar' ? 'ar' : 'en'}
      onSubmit={handleSubmit}
    >
      <Label>
        <Astrics>*</Astrics>
        <Trans>evaluation_place</Trans>
      </Label>
      <Select
        placeholder={t("doesn't_matter")}
        defaultValue={[data?.branch?.id]}
        className='full-width'
        mode='multiple'
        onChange={(
          value: number,
          item: {
            defaultValue: {
              id: number;
              name: string;
              description: string;
              title: string;
            };
          }
        ) => {
          handleSelectOnChange(value, item);
        }}
      >
        {branchListData?.map((item: CompanyBranchDataProps) => {
          return (
            <Option
              key={item.id}
              title='branch_id'
              value={item.id}
              defaultValue={item.name}
            >
              <OptionValue>{item.name}</OptionValue>
            </Option>
          );
        })}
      </Select>
      <Hr />

      <Label>
        <Astrics>*</Astrics>
        <Trans>evaluation_dates</Trans>
      </Label>
      <DateTimePicker
        defaultValue={[
          dayjs(data?.startTime, dateFormat),
          dayjs(data?.endTime, dateFormat),
        ]}
        onChange={(data: any, dateString: any) => {
          handleRangePickerOnchange(data, dateString);
        }}
        disabledDate={disabledDate}
      />

      <Hr />

      <DriversInformation>
        <DriversLabel>
          <Trans>drivers_information</Trans>
        </DriversLabel>

        <Section>
          <SupSection>
            <Label>
              <Trans>age</Trans>
            </Label>
            <Select
              placeholder={t("doesn't_matter")}
              value={data?.age?.id}
              className={'jobs'}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='age_group_id'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue>{t("doesn't_matter")}</OptionValue>
              </Option>
              {configrationList?.ages?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option
                      key={item.id}
                      title='age_group_id'
                      value={item.id}
                      defaultValue={item.name}
                    >
                      <OptionValue>{item.name}</OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>

          <SupSection>
            <Label>
              <Astrics>*</Astrics> <Trans>drivers_number</Trans>
            </Label>

            <Input
              type='number'
              width='jobs'
              name='shoppers_count'
              maxLength='2'
              placeholder={t('drivers_number')}
              onChange={(e: Event) => handleInputChange(e)}
              errorName={errors.shoppers_count}
              ref={register({
                required: true,
                pattern: /^[0-9]*$/,
              })}
              className={'jobsinput'}
              value={data?.shoppersData?.count ? data?.shoppersData?.count : ''}
              onWheel={(e: any) => e.target.blur()}
            />
            {errors?.shoppers_count?.types?.required && (
              <Error>
                <Trans>required_error_message</Trans>
              </Error>
            )}
            {errors?.shoppers_count?.types?.pattern && (
              <Error>
                <Trans>accepts_only_numbers</Trans>
              </Error>
            )}
          </SupSection>
        </Section>

        <Section>
          <SupSection>
            <Label>
              <Trans>gender</Trans>
            </Label>
            <Select
              placeholder={t("doesn't_matter")}
              className={'jobs'}
              value={data?.gender}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='gender'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue>{t("doesn't_matter")}</OptionValue>
              </Option>
              {[
                { id: 'male', name: t('male') },
                { id: 'female', name: t('female') },
              ].map((item: { id: string; name: string }, index: number) => {
                return (
                  <Option key={index} title='gender' value={item.id}>
                    <OptionValue>{item.name}</OptionValue>
                  </Option>
                );
              })}
            </Select>
          </SupSection>

          <SupSection>
            <Label>
              <Trans>education</Trans>
            </Label>
            <Select
              placeholder={t("doesn't_matter")}
              className={'jobs'}
              value={data?.education?.id}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='education_id'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue>{t("doesn't_matter")}</OptionValue>
              </Option>
              {configrationList?.educations?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='education_id' value={item.id}>
                      <OptionValue>{item.name}</OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>
        </Section>

        <Section>
          <SupSection>
            <Label>
              <Trans>skills</Trans>
            </Label>
            <Select
              placeholder={t("doesn't_matter")}
              className={'jobs'}
              value={data?.skills}
              mode='multiple'
              allowClear={true}
              showArrow={true}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='skills'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue>{t("doesn't_matter")}</OptionValue>
              </Option>
              {configrationList?.skills?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='skills' value={item.name}>
                      <OptionValue>{item.name}</OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>

          <SupSection>
            <Label>
              <Trans>experiences</Trans>
            </Label>
            <Select
              placeholder={t("doesn't_matter")}
              className={'jobs'}
              value={data?.experience?.id}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='experience_id'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue>{t("doesn't_matter")}</OptionValue>
              </Option>
              {configrationList?.experiences?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='experience_id' value={item.id}>
                      <OptionValue>{item.name}</OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>
        </Section>

        <Section>
          <SupSection>
            <Label>
              <Trans>languages</Trans>
            </Label>
            <Select
              placeholder={t("doesn't_matter")}
              className={'jobs'}
              value={data?.languages}
              mode='multiple'
              allowClear={true}
              showArrow={true}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='languages'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue>{t("doesn't_matter")}</OptionValue>
              </Option>
              {configrationList?.languages?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option key={item.id} title='languages' value={item.name}>
                      <OptionValue>{item.name}</OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>

          <SupSection>
            <Label>
              <Trans>nationality</Trans>
            </Label>
            <Select
              placeholder={t("doesn't_matter")}
              className={'jobs'}
              value={data?.nationality?.id}
              onChange={(
                value: number,
                item: {
                  defaultValue: {
                    id: number;
                    name: string;
                    description: string;
                    title: string;
                  };
                }
              ) => {
                handleSelectOnChange(value, item);
              }}
            >
              <Option
                title='nationality_id'
                value={null}
                defaultValue={"doesn't_matter"}
              >
                <OptionValue>{t("doesn't_matter")}</OptionValue>
              </Option>
              {configrationList?.nationalities?.map(
                (item: { id: number; name: string }) => {
                  return (
                    <Option
                      key={item.id}
                      title='nationality_id'
                      value={item.id}
                    >
                      <OptionValue>{item.name}</OptionValue>
                    </Option>
                  );
                }
              )}
            </Select>
          </SupSection>
        </Section>
        <OtherTerms language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <P>
            <Trans>the_shopper_will_pay_for_something!</Trans>
          </P>
          <SwitchBtn
            onChange={(value) => handleOnChangeSwitchButton(value)}
            checked={data?.extra_pay}
          />
        </OtherTerms>

        {data?.extra_pay && (
          <Section>
            <SupSection>
              <Label>
                <Trans>extra_item_description</Trans>
              </Label>
              <Input
                name='extra_item'
                defaultValue={data?.extraPay.name}
                onChange={(e: Event) => handleInputChange(e)}
                errorName={errors.extra_item}
                ref={register({ required: true })}
                width='extraItem'
                type='text'
                placeholder={t('extra_item_placeholder')}
                className={'jobsinput'}
              />
              {errors.extra_item && (
                <Error>
                  <Trans>required_error_message</Trans>
                </Error>
              )}
            </SupSection>
            <SupSection>
              <Label>
                <Trans>price_in_sar</Trans>
              </Label>
              <Input
                placeholder='SAR'
                width='price'
                type='number'
                maxLength='3'
                name='extra_item_price'
                onChange={(e: Event) => handleInputChange(e)}
                errorName={errors.extra_item_price}
                ref={register({
                  required: true,
                  min: 0,
                })}
                defaultValue={data.extraPay.amount ? data.extraPay.amount : ''}
                onWheel={(e: any) => e.target.blur()}
              />
              {errors?.extra_item_price?.types?.required && (
                <Error>
                  <Trans>required_error_message</Trans>
                </Error>
              )}

              {errors?.extra_item_price?.types?.min && (
                <Error>
                  <Trans>min_0</Trans>
                </Error>
              )}
            </SupSection>
          </Section>
        )}
      </DriversInformation>

      <StepsActions
        style={{
          display: 'flex',
          gap: '0 20px',
          flexDirection: i18n.language === 'ar' ? 'row-reverse' : 'row',
          justifyContent: 'start',
        }}
      >
        <Button
          id='next-button'
          type='primary'
          onClick={handleSubmit}
          disabled={formState.isSubmitting}
        >
          <Trans>next</Trans>
        </Button>

        {current > 0 && (
          <Button onClick={prev}>
            <Trans>previous</Trans>
          </Button>
        )}
      </StepsActions>
    </DescriptionFormContainer>
  );
};

export default DemandsForm;
