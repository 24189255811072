/** @format */

import { Link } from "react-router-dom";
import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";
import User from "assets/svg/User";
import { Form as AntdForm } from "antd";

type IProps = {
  profile?: boolean;
  noValidate?: boolean;
  lang?: string;
};

export const Form = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.profile ? "center" : "flex-end")};
  padding: 0 25px;
  margin: 0 auto;
  width: ${(props) => props.profile && "66%"};
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: ${(props: { profile?: boolean | any }) =>
    props.profile ? "0 auto" : "unset"};
    max-width: ${(props: { profile?: boolean | any }) =>
    props.profile ? "96%" : "unset"};
  }

  @media (max-width: 320px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: ${(props: { profile?: boolean | any }) =>
    props.profile ? "0 auto" : "unset"};

    max-width: ${(props: { profile?: boolean | any }) =>
    props.profile ? "86%" : "unset"};
  }
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const SubTitle = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_38};
`;

export const LinkStyle = styled(Link)`
  color: ${Colors.dark_mint};
  text-decoration: none;
  :hover {
    color: ${Colors.dark_mint};
  }
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  direction: rtl;
  margin-left: ${(props: { language?: string }) =>
    props.language === "en" && "5px"};
`;

export const TitleContainer = styled.div`
  margin-bottom: 55px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const InputsContainer = styled(AntdForm) <{
  profile?: boolean;
  language?: string;
}>`
  width: ${(props) => (props.profile ? "37%" : "99%")};
  position: relative;
  display: flex;
  flex-direction: column;
  direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
  margin: ${(props) => (props.profile ? "0 auto" : "unset")};
  @media (max-width: 700px) {
    width: 96%;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 75px;
  align-self: end;
  align-self: ${(props: { language?: string }) =>
    props.language === "ar" ? "start" : "end"};

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
  }

  @media (max-width: 700px) {
    width: 96%;
    flex-direction: column;
    .forget-password {
      margin-top: 25px;
      font-size: 14px;
    }
  }
`;

export const RegiaterBottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 75px;
  gap: 20px 0;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
  }

  @media (max-width: 700px) {
    width: 96%;
    flex-direction: column;
    .forget-password {
      margin-top: 25px;
      font-size: 14px;
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

type Props = {
  right?: boolean;
  login?: boolean;
};

export const LeftContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  width: ${(props: Props) => (props.right ? "80%" : "100%")};
  height: ${(props: Props) => (props.login ? "100vh" : "unset")};
  position: relative;

  @media (max-width: 700px) {
    margin-top: 50px;
    height: unset;
    width: 100%;
  }

  @media (max-width: 320px) {
    margin: 50px auto;
    height: unset;
  }
`;

export const RightContainer = styled.div`
  flex-direction: column;
  height: 100vh;
  text-align: center;
  width: 20%;
  margin: 0 1.5px 0 0;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #cccccc26;
  background-color: ${Colors.white};
  position: Fixed;
  right: ${(props: { right?: boolean }) => props.right && "0px"};
`;

type SubContainerProps = {
  language?: string;
  profile?: boolean;
};
//@ts-ignore
export const SubContainer = styled.div<SubContainerProps>`
  display: flex;
  flex-direction: row-reverse;
  width: ${(props) => (props.profile ? "100%" : "94%")};
  margin: ${(props) => (props.profile ? "0px" : "0 auto")};
  margin-bottom: 35px;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 320px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86%;
  }
`;

export const PrefixSpan = styled.div`
  position: absolute;
  top: 60px;
  left: ${(props) => (props.lang === "ar" ? " 28px" : "20px")};
  transform: translateY(-50%);
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
`;

export const Img = styled.img`
  width: 120px;
  border-radius: 50%;
  height: 120px;
  margin-top: 20px;
`;
export const ImgContainer = styled.div`
  top: 85%;
  position: absolute;
  left: 45%;
`;

export const UserStyle = styled(User)`
  margin-top: 23px;
  margin-left: 31px;
`;

export const Span = styled.span`
  position: absolute;
  left: 51px;
  top: 148px;
  background: white;
  width: 23px;
  height: 23px;
  padding-top: 5px;
  box-shadow: 0 2px 7px 0 rgb(67 67 67 / 24%);
  border-radius: 50%;
  padding-left: 5px;
`;

export const FileContainer = styled.div`
  width: 100%;
  border-radius: 50%;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: auto;

  @media (max-width: 600px) {
    height: unset;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    margin-right: 21%;
  }
`;

export const TermsTitle = styled.p`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  text-align: center;
  margin: 0 auto;
  width: 323px;

  a {
    color: ${Colors.dark_mint};
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
    align-self: baseline;
    font-size: 11px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  width: 100%;
`;

export const StyledVerficationBox = styled.div`
  background-color: ${Colors.white};
  color: black;
  width: 330px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 10px;
  box-shadow: 3px 3px 5px 6px #ccc;
  margin-bottom: 20px;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  align-self: baseline;
`;

export const Parghrap = styled.p`
  font-family: Famtree;
  font-size: 12px;
  color: black;
  padding-right: 2px;
  margin: 0px;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 60%;
  .mobile-btn {
    background-color: #44c874;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    font-family: Famtree;
    font-size: 14px;
  }
  .btn-text {
    margin: 0 6px;
  }
`;

export const ProfileSubContainer = styled.div`
  flex-direction: column;

  width: 360px;
  margin-top: 47px;
  display: flex;

  @media (max-width: 700px) {
    width: 98%;
  }
`;

export const StyledLink = styled.p`
  font-family: Famtree;
  font-size: 12px;
  text-decoration: underline;
`;

export const CustomForm: any = styled(AntdForm) <{
  profile?: boolean;
  language?: string;
}>`
  width: ${(props) => (props.profile ? "unset" : "99%")};
  position: relative;
  display: flex;
  flex-direction: column;
  direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
  margin: ${(props) => (props.profile ? "0 auto" : "unset")};
  @media (max-width: 700px) {
    width: 96%;
  }

  .ant-form-item {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: end;
    }
  }
`;
