/** @format */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'common/Button';
import { Input } from 'common/Input';
import TextArea from 'common/TextArea';
import { Error } from '../../style';
import { Label, DescriptionFormContainer, StepsActions } from '../style';
import { EditEvaluationJob } from 'infrastructure/interfaces/jobs.interface';
import ReactSelect from 'common/ReactSelect';

const DescriptionForm = ({
  templates,
  handleSelectOnChange,
  handleInputChange,
  template,
  handleSubmit,
  register,
  errors,
  formState,
}: EditEvaluationJob) => {
  const { t, i18n } = useTranslation();

  const companyOptions = templates
    ?.map((item) => {
      if (item.created_by === 'company') {
        return {
          value: item.id,
          label: item.name,
          defaultValue: item,
          title: 'evaluation_template_id',
          key: item.id,
        };
      }
      return {};
    })
    .filter(Boolean);

  const adminOptions = templates
    ?.map((item) => {
      if (item.created_by === 'admin') {
        return {
          value: item.id,
          label: item.name,
          defaultValue: item,
          title: 'evaluation_template_id',
          key: item.id,
        };
      }
      return {};
    })
    .filter(Boolean);

  const templateOptions = templates?.map((item: any) => {
    return {
      value: item.id,
      label: item.name,
      defaultValue: item,
      title: 'evaluation_template_id',
      key: item.id,
    };
  });

  const groupedOptions = [
    {
      label: <Trans>company_templates</Trans>,
      options: companyOptions,
    },
    {
      label: <Trans>suggested_templates</Trans>,
      options: adminOptions,
    },
  ];

  return (
    <DescriptionFormContainer
      language={i18n.language === 'ar' ? 'ar' : 'en'}
      onSubmit={handleSubmit}
    >
      <Label>
        <Trans>evaluation_template</Trans>
      </Label>
      <ReactSelect
        className='template-select'
        options={groupedOptions}
        groupedOptions={templateOptions}
        value={template && template.template && template.template.id}
        onChange={(item: any) => {
          handleSelectOnChange(item.value, item, 'evaluationTemplateSelect');
        }}
        placeholder={t('select_evaluation_template')}
        errorStyle={errors.evaluationTemplateSelect}
      />
      {errors?.evaluationTemplateSelect && (
        <Error>
          <Trans>required_error_message</Trans>
        </Error>
      )}

      <Label>
        <Trans>job_name</Trans>
      </Label>
      <Input
        name='name'
        width='100'
        errorName={errors.name}
        value={template?.name ? template.name : ''}
        onChange={(e: Event | { target: { name: string; value: number } }) =>
          handleInputChange(e)
        }
        ref={register({ required: true })}
        className={'jobsinput'}
        placeholder={t('job_name_placeholder')}
      />
      {errors.name && (
        <Error>
          <Trans>required_error_message</Trans>
        </Error>
      )}

      <Label>
        <Trans>job_description</Trans>
      </Label>

      <TextArea
        name='description'
        errorName={errors.description}
        value={template?.description ? template.description : ''}
        onChange={(e: Event) => handleInputChange(e)}
        inputRef={register({ required: true })}
        placeholder={t('job_description_placeholder')}
      />

      {errors.description && (
        <Error>
          <Trans>required_error_message</Trans>
        </Error>
      )}

      <Label>
        <Trans>instructions</Trans>
      </Label>

      <TextArea
        name='instructions'
        errorName={errors.instructions}
        onChange={(e: Event) => handleInputChange(e)}
        inputRef={register({ required: true })}
        value={template?.instructions ? template.instructions : ''}
        placeholder={t('job_instructions_placeholder')}
      />
      {errors.instructions && (
        <Error>
          <Trans>required_error_message</Trans>
        </Error>
      )}
      <StepsActions>
        <Button
          id='next-button'
          type='primary'
          onClick={handleSubmit}
          htmlType='submit'
          disabled={formState.isSubmitting}
        >
          <Trans>next</Trans>
        </Button>
      </StepsActions>
    </DescriptionFormContainer>
  );
};

export default DescriptionForm;
