/** @format */

import React, { useEffect, useState } from 'react';
import { Input } from 'common/Input';
import { P } from 'pages/EvaluationTemplate/EvaluationDetails/style';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Label } from 'pages/style';
import { Form } from 'pages/Wallet/style';
import { InputsContainer, InputWrapper } from '../../style';
import { StyledAlert } from 'pages/Home/style';
import LiveIcon from 'assets/svg/live-ic';
import { useQuery } from 'react-query';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';

const ChargeWithCreditForm = () => {
  const { t, i18n } = useTranslation();
  const [response, setResponse] = useState('');
  const [cardNumValue, setCardNumValue] = useState('');
  const [expYearValue, setExpYearValue] = useState('');
  const [expMonthValue, setExpMonthValue] = useState('');
  const [cvvValue, setCvvValue] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://secure.paytabs.sa/payment/js/paylib.js';
    script.async = true;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, [cardNumValue]);

  const onCardNumChange = (e) => {
    const re = /^[0-9 ]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setCardNumValue(e.target.value);
    }
  };

  const onExpYearChange = (e) => {
    const re = /^[0-9 ]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setExpYearValue(e.target.value);
    }
  };

  const onExpMonthChange = (e) => {
    const re = /^[0-9 ]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setExpMonthValue(e.target.value);
    }
  };

  const onCvvChange = (e) => {
    const re = /^[0-9 ]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setCvvValue(e.target.value);
    }
  };

  const onAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const onSubmit = () => {
    var myform = document.getElementById('payform');
    let paylib = window.paylib;
    paylib.inlineForm({
      key: process.env.REACT_APP_PAYTABS_KEY,
      form: myform,
      callback: function (response) {
        document.getElementById('paymentErrors').innerHTML = '';
        setResponse(response);
        if (
          response.error &&
          response.errorText === 'Card number is not valid'
        ) {
          paylib.handleError(
            (document.getElementById('paymentErrors').innerHTML = `${t(
              'wrong-card-number'
            )}`)
          );
        }

        if (
          response.error &&
          response.errorText === 'Card security code is not valid'
        ) {
          paylib.handleError(
            (document.getElementById('paymentErrors').innerHTML = t(
              'wrong-card-insurance-number'
            ))
          );
        }

        if (
          response.error &&
          response.errorText === 'Card expiry is not valid'
        ) {
          paylib.handleError(
            (document.getElementById('paymentErrors').innerHTML = t(
              'wrong-card-expiration-date'
            ))
          );
        }
      },
    });
  };

  const { data: configrationList } = useQuery(
    ['configrationList'],
    () => fetchGetRequests('api/configurations'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return (
    <Form id='payform' onSubmit={onSubmit} action='/paytabs' noValidate>
      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        {amount && (
          <StyledAlert
            message={
              <Trans
                values={{
                  amount: Number(
                    (
                      amount /
                      (1 +
                        configrationList?.response?.configuration.settings
                          .vat_percentage /
                          100)
                    ).toFixed(2)
                  ),
                  realAmount: amount,
                  vatPercentage:
                    configrationList?.response?.configuration.settings
                      .vat_percentage,

                  percentage: Number(
                    (
                      amount -
                      amount /
                        (1 +
                          configrationList?.response?.configuration.settings
                            .vat_percentage /
                            100)
                    ).toFixed(2)
                  ),
                }}
              >
                message_alert
              </Trans>
            }
            type='info'
            showIcon
            closable
            style={
              i18n.language === 'ar'
                ? { direction: 'rtl' }
                : { direction: 'ltr' }
            }
            icon={<LiveIcon />}
          />
        )}

        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>amount</Trans>
        </Label>
        <Input
          width='full_width'
          style={{ marginBottom: '24px', direction: 'ltr' }}
          name='amount'
          type='number'
          maxLength='6'
          title='required'
          autoFocus='true'
          min='1'
          max=' 500000'
          placeholder={t('sar')}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => onAmountChange(e)}
        />
      </InputsContainer>
      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>card_number</Trans>
        </Label>
        <Input
          width='full_width'
          style={{ marginBottom: '24px', direction: 'ltr' }}
          min='1'
          data-paylib='number'
          type='text'
          maxLength={response.error ? '19' : '16'}
          title='please enter number only'
          name='cardNumber'
          onChange={(e) => onCardNumChange(e)}
        />
      </InputsContainer>
      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <CvvAndExpiresContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <InputWrapper>
            <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Trans>month</Trans>
            </Label>
            <Input
              style={{ direction: 'ltr' }}
              width='full_width'
              min='1'
              data-paylib='expmonth'
              type='text'
              maxLength='2'
              title='please enter number only'
              name='expmonth'
              onChange={(e) => onExpMonthChange(e)}
              value={expMonthValue}
            />
          </InputWrapper>
          <InputWrapper>
            <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Trans>year</Trans>
            </Label>
            <Input
              style={{ direction: 'ltr' }}
              width='full_width'
              min='1'
              data-paylib='expyear'
              type='text'
              maxLength='2'
              title='please enter number only'
              name='expyear'
              onChange={(e) => onExpYearChange(e)}
              value={expYearValue}
            />
          </InputWrapper>
          <InputWrapper>
            <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Trans>cvv</Trans>
            </Label>
            <Input
              style={{ direction: 'ltr' }}
              width='full_width'
              type='text'
              min='1'
              data-paylib='cvv'
              maxLength='3'
              name='cvv'
              placeholder={'CVV'}
              onChange={(e) => onCvvChange(e)}
              value={cvvValue}
            />
          </InputWrapper>
        </CvvAndExpiresContainer>
      </InputsContainer>
      <P id='paymentErrors' style={{ color: 'red' }} />
      <InputsContainer
        language={i18n.language === 'ar' ? 'ar' : 'en'}
        profile
        style={{ width: '72%' }}
      >
        <input
          id='credit-submit'
          className={'payment'}
          type='submit'
          value={t('charge')}
          onClick={onSubmit}
          style={{
            backgroundColor: '#44c874',
            color: 'white',
            borderColor: '#44c874',
            alignSelf: 'flex-end',
            width: '106px',
            height: '48px',
            borderRadius: '10px',
            fontFamily: 'Famtree',
            fontSize: '14px',
            fontWeight: 'bold',
            marginTop: '20px',
            border: '0',
          }}
        />
      </InputsContainer>
    </Form>
  );
};

export default ChargeWithCreditForm;

export const CvvAndExpiresContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  direction: ${(props) => (props.language === 'ar' ? 'rtl' : 'ltr')};
`;
