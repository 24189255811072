/** @format */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { ProtectedRoutes } from 'routes/protected';
import GlobalFonts from 'assets/fonts/fonts';
import Login from 'pages/User/Login';
import Register from 'pages/User/Register';
import NotFound from 'common/NotFound';
import { ConsultationForm } from 'pages/User/ConsultationForm';
import { ForgotPassword } from 'pages/User/ForogtPassword';
import { ResetPassowrd } from 'pages/User/ResetPassword';
import { Verfication } from 'pages/User/Verfication';
import { Helmet } from 'react-helmet';
import Landing from 'pages/Static/Landing/landing';
import TermsAndConditionsShoppers from 'pages/Static/termsAndConditionsShoppers';
import InvoiceQr from 'pages/Invoices/invoiceQr';
import WalletQr from 'pages/Wallet/walletQr';
import { Home } from 'pages/Home';
import CompanyBranch from 'pages/CompanyBranch';
import EvaluationTemplates from 'pages/EvaluationTemplate';
import Details from 'pages/Managers/Details';
import HomeDetails from 'pages/Home/Details';
import CreateCompanyBranch from 'pages/CompanyBranch/Create';
import EditCompanyBranch from 'pages/CompanyBranch/Edit';
import BranchDetails from 'pages/CompanyBranch/BranchDetails';
import CreateEvaluationTemplate from 'pages/EvaluationTemplate/Create';
import EditEvaluationTemplate from 'pages/EvaluationTemplate/Edit';
import EvaluationDetails from 'pages/EvaluationTemplate/EvaluationDetails';
import Jobs from 'pages/Jobs';
import CreateEvaluationJob from 'pages/Jobs/Create';
import EditEvaluationJob from 'pages/Jobs/Edit';
import JobsDetails from 'pages/Jobs/Details';
import JobsPayment from 'pages/Jobs/Payment';
import Ratings from 'pages/Ratings';
import { Invoices } from 'pages/Invoices';
import Wallet from 'pages/Wallet';
import { Charge } from 'pages/Wallet/Charge';
import { PayTabsForm } from 'pages/payTabsForm';
import Managers from 'pages/Managers';
import Form from 'pages/Managers/Form/index';
import { Help } from 'pages/Help';
import TermsAndConditions from 'pages/Static/termsAndConditions';
import ContactUs from 'pages/Static/contactUs';
import { Profile } from 'pages/User/Profile';
import storage from 'infrastructure/utils/storage';

const App = () => {
  const { i18n } = useTranslation();

  const isAuthed = !!storage.getItem('access_token');
  useEffect(() => {
    const URL: any = process.env.REACT_APP_BASE_API_URL;
    if (!/(dev|test)/.test(URL)) {
      document.addEventListener('contextmenu', (event) =>
        event.preventDefault()
      );
      document.onkeydown = function (e) {
        if (e.code === 'F12') {
          return false;
        }
      };
      return function cleanup() {
        document.removeEventListener('contextmenu', (event) =>
          event.preventDefault()
        );
      };
    }
  }, []);

  return (
    <Router>
      <GlobalFonts />
      <Helmet>
        <title>{i18n.language === 'ar' ? 'تدري' : 'Tdree'}</title>
        <meta
          name='keywords'
          content='Tdree, 
          Tdre, 
          Tdree app,
          Mystery shopping ,
          Mystery shopper,
          Mystery shopping Saudi Arabia,
          Mystery shopping jobs Saudi Arabia,
          Mystery shoppers jobs Saudi Arabia ,
          تدري ,
          متسوق سري ,
          متسوق خفي  ,
          التسوق السري ,
          تطبيق المتسوق السري ,
          تطبيق المتسوق الخفي ,
          خدمات التسوق السري في السعودية  ,
          خدمة التسوق السري في السعودية ,
          وظيفة المتسوق السري ,
          وظيفة المتسوق الخفي ,
          وظائف المتسوق السري ,
          المتسوق السري وزارة الصحة ,
          المتسوق الخفي وزارة الصحة ,
          شركات المتسوق الخفي ,
          شركات المتسوق السري ,
          تطوير خدمة العملاء ,
          تحسين خدمة العملاء ,
          تحسين تجربة العميل  ,
          تحسين الجودة  ,
          جودة الخدمات ,
          مراقبة الجودة الشركات  ,
          '
        />
        ‍
        <meta
          name='description'
          content='Find all the best quality products your company may need'
        />
        <meta
          property='image'
          content='https://tdree-assets.s3.me-south-1.amazonaws.com/logo-svg.svg'
        />
        <meta
          property='og:title'
          content={`${i18n.language === 'ar' ? 'تدري' : 'Tdree'}`}
        />
        <meta
          property='og:description'
          content='Find all the best quality products your company may need'
        />
        <meta
          property='og:image'
          content='https://tdree-assets.s3.me-south-1.amazonaws.com/logo-svg.svg'
        />
        <meta
          property='og:url'
          content={`${i18n.language === 'ar' ? 'تدري' : 'Tdree'}`}
        />
        <meta
          property='og:site_name'
          content={`${i18n.language === 'ar' ? 'تدري' : 'Tdree'}`}
        />
        <meta property='og:locale' content='ar-EG' />
        <meta property='og:type' content='article' />
      </Helmet>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route
          path='/login'
          element={isAuthed ? <Navigate to='/home' /> : <Login />}
        />
        <Route
          path='/register'
          element={isAuthed ? <Navigate to='/home' /> : <Register />}
        />
        <Route path='/consultation' element={<ConsultationForm />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path={'/reset-password/*'} element={<ResetPassowrd />} />
        <Route path={'/email/verify/*'} element={<Verfication />} />
        <Route
          path={'/terms-conditions-shoppers'}
          element={<TermsAndConditionsShoppers />}
        />
        <Route path={'/invoices/:id'} element={<InvoiceQr />} />
        <Route path={'/wallet/:id'} element={<WalletQr />} />

        <Route
          path='home'
          element={
            <ProtectedRoutes>
              <Home />
            </ProtectedRoutes>
          }
        />

        <Route
          path='home/summary/:params'
          element={
            <ProtectedRoutes name>
              <HomeDetails />
            </ProtectedRoutes>
          }
        />

        <Route
          path='branches'
          element={
            <ProtectedRoutes>
              <CompanyBranch />
            </ProtectedRoutes>
          }
        />

        <Route
          path='branches/create'
          element={
            <ProtectedRoutes name>
              <CreateCompanyBranch />
            </ProtectedRoutes>
          }
        />

        <Route
          path='branches/edit/:id'
          element={
            <ProtectedRoutes name>
              <EditCompanyBranch />
            </ProtectedRoutes>
          }
        />

        <Route
          path='branches/:id'
          element={
            <ProtectedRoutes name>
              <BranchDetails />
            </ProtectedRoutes>
          }
        />

        <Route
          path='evaluation-templates'
          element={
            <ProtectedRoutes>
              <EvaluationTemplates />
            </ProtectedRoutes>
          }
        />

        <Route
          path='evaluation-templates/create'
          element={
            <ProtectedRoutes name>
              <CreateEvaluationTemplate />
            </ProtectedRoutes>
          }
        />

        <Route
          path='evaluation-templates/edit/:id'
          element={
            <ProtectedRoutes name>
              <EditEvaluationTemplate />
            </ProtectedRoutes>
          }
        />

        <Route
          path='evaluation-templates/:id'
          element={
            <ProtectedRoutes name>
              <EvaluationDetails />
            </ProtectedRoutes>
          }
        />

        <Route
          path='jobs'
          element={
            <ProtectedRoutes>
              <Jobs />
            </ProtectedRoutes>
          }
        />

        <Route
          path='jobs/create'
          element={
            <ProtectedRoutes name>
              <CreateEvaluationJob />
            </ProtectedRoutes>
          }
        />

        <Route
          path='jobs/payment'
          element={
            <ProtectedRoutes name>
              <JobsPayment />
            </ProtectedRoutes>
          }
        />

        <Route
          path='jobs/edit/:id'
          element={
            <ProtectedRoutes name>
              <EditEvaluationJob />
            </ProtectedRoutes>
          }
        />

        <Route
          path='jobs/:id'
          element={
            <ProtectedRoutes name>
              <JobsDetails />
            </ProtectedRoutes>
          }
        />

        <Route
          path='ratings'
          element={
            <ProtectedRoutes>
              <Ratings />
            </ProtectedRoutes>
          }
        />

        <Route
          path='invoices'
          element={
            <ProtectedRoutes>
              <Invoices />
            </ProtectedRoutes>
          }
        />

        <Route
          path='wallet'
          element={
            <ProtectedRoutes>
              <Wallet />
            </ProtectedRoutes>
          }
        />

        <Route
          path='charge'
          element={
            <ProtectedRoutes name>
              <Charge />
            </ProtectedRoutes>
          }
        />

        <Route
          path='paytabs'
          element={
            <ProtectedRoutes>
              <PayTabsForm />
            </ProtectedRoutes>
          }
        />

        <Route
          path='managers'
          element={
            <ProtectedRoutes>
              <Managers />
            </ProtectedRoutes>
          }
        />

        <Route
          path='managers/create'
          element={
            <ProtectedRoutes name>
              <Form />
            </ProtectedRoutes>
          }
        />

        <Route
          path='managers/edit/:id'
          element={
            <ProtectedRoutes name>
              <Form />
            </ProtectedRoutes>
          }
        />

        <Route
          path='managers/:id'
          element={
            <ProtectedRoutes name>
              <Details />
            </ProtectedRoutes>
          }
        />

        <Route
          path='help'
          element={
            <ProtectedRoutes>
              <Help />
            </ProtectedRoutes>
          }
        />

        <Route
          path='termsAndConditions'
          element={
            <ProtectedRoutes>
              <TermsAndConditions />
            </ProtectedRoutes>
          }
        />

        <Route
          path='contactUs'
          element={
            <ProtectedRoutes>
              <ContactUs />
            </ProtectedRoutes>
          }
        />

        <Route
          path='profile'
          element={
            <ProtectedRoutes name>
              <Profile />
            </ProtectedRoutes>
          }
        />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
