import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const ARContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ENContainer = styled.div`
  display: flex;
  flex-direction: row;
  direction: ltr;
`;

export const ARSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 20px;
  min-height: 800px;
  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const ENSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: ltr;
  margin: 0 20px;
  min-height: 800px;
`;

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 32px 0px;
`;
