/** @format */

import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

//@ts-ignore
export const StyledDatePicker = styled(DatePicker)`
  .ant-btn {
    background: ${Colors.dark_mint};
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${Colors.dark_mint};
  }
  &.ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: ${Colors.dark_mint};
  }
`;

//@ts-ignore
export const StyledPicker = styled(RangePicker)`
  background-color: ${(props: any) =>
    props.style ? Colors.lipstick_5 : Colors.black_4};
  border-radius: 8px;
  height: 51px;
  border-color: ${(props: any) =>
    props.style ? Colors.lipstick : Colors.black_4};
  font-family: Famtree;
  font-size: 16px;
  font-weight: normal;

  &.ant-picker-time-panel-cell-inner {
    background-color: unset;
  }

  &.ant-picker-focused {
    border: 0.5px solid ${Colors.black_4};
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: ${Colors.black_4} !important;
  }

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${Colors.dark_mint};
  }
`;
